import {
  fetchListBusinessSuccess,
  fetchListBusinessFailed,
  fetchBusinessDetailFailed,
  fetchBusinessDetailSuccess,
  fetchBusinessProductSuccess,
  fetchBusinessProductFailed,
  deleteBusinessProductSuccess,
  deleteBusinessProductFailed,
  createBusinessProductSuccess,
  createBusinessProductFailed,
  updateBusinessProductSuccess,
  updateBusinessProductFailed,
  fetchListBusinessInvoiceSuccess,
  fetchListBusinessInvoiceFailed,
  fetchBusinessInvoiceDetailSuccess,
  fetchBusinessInvoiceDetailFailed,
  updateBusinessInvoiceSuccess,
  updateBusinessInvoiceFailed,
  fetchListBusinessEmployeesSuccess,
  fetchListBusinessEmployeesFailed,
  addNewBusinessEmployeeSuccess,
  addNewBusinessEmployeeFailed,
  updateBusinessEmployeeSuccess,
  updateBusinessEmployeeFailed,
  deleteBusinessEmployeeSuccess,
  deleteBusinessEmployeeFailed,
  importBusinessProductSuccess,
  importBusinessProductFailed,
  fetchEnterprisesSuccess,
  fetchEnterprisesFailed,
  fetchProductOfEnterprisesSuccess,
  fetchProductOfEnterprisesFailed,
} from 'actions/business';
import { call, put } from 'redux-saga/effects';
import {
  getListBusinessAssociation,
  getBusinessDetail,
  getBusinessProduct,
  deleteProductBusiness,
  createBusinessProduct,
  updateBusinessProduct,
  getBusinessInvoice,
  getBusinessInvoiceDetail,
  updateBusinessInvoice,
  getListBusinessEmployee,
  createBusinessEmployee,
  deleteBusinessEmployee,
  updateBusinessEmployee,
  importBusinessProductApi,
  getEnterPrises,
  getProductOfEnterprise,
} from 'apis/business';
import { toastError, toastSuccess } from 'helpers/toastHelper';
export function* userFetchListBusinessAssociation({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getListBusinessAssociation, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchListBusinessSuccess(data));
    } else {
      yield put(fetchListBusinessFailed(data));
    }
  } catch (error) {
    yield put(fetchListBusinessFailed(error.data));
  }
}

export function* userFetchBusinessAssociationDetail({ payload }) {
  try {
    const { businessId } = payload;
    const resp = yield call(getBusinessDetail, businessId);
    const { data } = resp;
    if (data.success) {
      yield put(fetchBusinessDetailSuccess(data));
    } else {
      yield put(fetchBusinessDetailFailed(data));
    }
  } catch (error) {
    yield put(fetchBusinessDetailFailed(error.data));
  }
}

export function* userFetchBusinessProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getBusinessProduct, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchBusinessProductSuccess(data));
    } else {
      yield put(fetchBusinessProductFailed(data));
    }
  } catch (error) {
    yield put(fetchBusinessProductFailed(error.data));
  }
}

export function* userDeleteBusinessProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(deleteProductBusiness, params);
    const { data } = resp;
    if (data.success) {
      yield put(deleteBusinessProductSuccess(params));
    } else {
      yield put(deleteBusinessProductFailed(params));
    }
  } catch (error) {
    yield put(deleteBusinessProductFailed(error.data));
  }
}

export function* userCreateBusinessProduct({ payload }) {
  try {
    const { params, callback } = payload;
    const resp = yield call(createBusinessProduct, params);
    const { data } = resp;
    if (data.success) {
      yield put(createBusinessProductSuccess(data));
      callback();
    } else {
      yield put(createBusinessProductFailed(data));
    }
  } catch (error) {
    yield put(createBusinessProductFailed(error));
  }
}

export function* userImportBusinessProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(importBusinessProductApi, params);
    const { data } = resp;
    const { list_products, total_success } = data.data;
    const updateProducts = list_products.filter(item => item.message === 'update').length;
    const createProducts = list_products.filter(item => item.message !== 'update').length;
    if (total_success > 0) {
      yield put(importBusinessProductSuccess(data));
      if (list_products.filter(item => item.message === 'update').length > 0)
        toastSuccess(`Cập nhật ${updateProducts} sản phẩm thành công`);
      if (list_products.filter(item => item.message !== 'update').length > 0)
        toastSuccess(`Tải dữ liệu thành công với ${createProducts} sản phẩm`);
    } else {
      yield put(importBusinessProductFailed(data));
    }
  } catch (error) {
    yield put(importBusinessProductFailed(error));
  }
}

export function* userUpdateBusinessProduct({ payload }) {
  try {
    const { params, callback, productId } = payload;
    const resp = yield call(updateBusinessProduct, { productId: productId, formData: params });
    const { data } = resp;
    if (data.success) {
      yield put(updateBusinessProductSuccess(data));
      callback();
    } else {
      yield put(updateBusinessProductFailed(data));
    }
  } catch (error) {
    yield put(updateBusinessProductFailed(error.data));
  }
}

export function* userFetchBusinessInvoice({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getBusinessInvoice, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchListBusinessInvoiceSuccess(data));
    } else {
      yield put(fetchListBusinessInvoiceFailed(data));
    }
  } catch (error) {
    yield put(fetchListBusinessInvoiceFailed(error.data));
  }
}

export function* userFetchBusinessInvoiceDetail({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getBusinessInvoiceDetail, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchBusinessInvoiceDetailSuccess(data));
    } else {
      yield put(fetchBusinessInvoiceDetailFailed(data));
    }
  } catch (error) {
    yield put(fetchBusinessInvoiceDetailFailed(error.data));
  }
}

export function* userUpdateBusinessInvoice({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateBusinessInvoice, params);
    const { data } = resp;
    if (data.success) {
      yield put(updateBusinessInvoiceSuccess(data));
    } else {
      yield put(updateBusinessInvoiceFailed(data));
    }
  } catch (error) {
    yield put(updateBusinessInvoiceFailed(error.data));
  }
}

export function* userFetchBusinessEmployee({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getListBusinessEmployee, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchListBusinessEmployeesSuccess(data));
    } else {
      yield put(fetchListBusinessEmployeesFailed(data));
    }
  } catch (error) {
    yield put(fetchListBusinessEmployeesFailed(error.data));
  }
}

export function* userAddNewBusinessEmployee({ payload }) {
  try {
    const { params } = payload;
    const { callback } = params;
    const resp = yield call(createBusinessEmployee, params);
    const { data } = resp;
    if (data.success) {
      yield put(addNewBusinessEmployeeSuccess(data));
      callback();
    } else {
      yield put(addNewBusinessEmployeeFailed(data));
    }
  } catch (error) {
    yield put(addNewBusinessEmployeeFailed(error.data));
  }
}

export function* userUpdateBusinessEmployee({ payload }) {
  try {
    const { params } = payload;
    const { callback } = params;
    const resp = yield call(updateBusinessEmployee, params);
    const { data } = resp;
    if (data.success) {
      yield put(updateBusinessEmployeeSuccess(data));
      callback();
    } else {
      yield put(updateBusinessEmployeeFailed(data));
    }
  } catch (error) {
    yield put(updateBusinessEmployeeFailed(error.data));
  }
}

export function* userDeleteBusinessEmployee({ payload }) {
  try {
    const { params } = payload;
    const { employeeId } = params;
    const resp = yield call(deleteBusinessEmployee, params);
    const { data } = resp;
    if (data.success) {
      yield put(deleteBusinessEmployeeSuccess({ employeeId: employeeId }));
    } else {
      yield put(deleteBusinessEmployeeFailed(data));
    }
  } catch (error) {
    yield put(deleteBusinessEmployeeFailed(error.data));
  }
}
export function* fetchListEnterprises({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getEnterPrises, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchEnterprisesSuccess(data));
    } else {
      yield put(fetchEnterprisesFailed(data));
    }
  } catch (error) {
    yield put(fetchEnterprisesFailed(error.data));
  }
}
export function* fetchProductOfEnterprise({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProductOfEnterprise, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchProductOfEnterprisesSuccess(data));
    } else {
      yield put(fetchProductOfEnterprisesFailed(data));
    }
  } catch (error) {
    yield put(fetchProductOfEnterprisesFailed(error.data));
  }
}
