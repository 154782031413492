import React, { useState } from 'react';
import SectionWrapper from '../wrapper/SectionWrapper';
import { Button, Col, Row } from 'react-bootstrap';
import parse from "html-react-parser";
import "./EnterpriseDescriptionMobile.scss";

const EnterpriseDescriptionMobile = ({ enterprise }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderEnterpriseDescriptionContent = () => {
    const isHiddenContentOverlay = enterprise.content.length > 400;

    return (
      <>
        {enterprise.content.length > 0 ? <div className="my-2">
          <Row>
            <Col>
              <div className="pl-2">
                <div className="website mb-3">
                  <div className="fw-bold">Mô tả sản phẩm</div>
                  <div className={isHiddenContentOverlay ? descriptionClass : ""}>
                    {parse(enterprise.content)}
                  </div>
                  {isHiddenContentOverlay && <Button variant="link" onClick={toggleExpand} className='button-hide-expand'>
                    {isExpanded ? 'Thu gọn' : 'Xem thêm'}
                  </Button>}
                </div>
              </div>
            </Col>
          </Row>
        </div> : <div class="font-italic gray-clr ml-2">Không có nội dung!</div>}
      </>
    )
  }

  const descriptionClass = isExpanded ? 'description expanded' : 'description collapsed';
  return (
    <>
      <SectionWrapper>
        {renderEnterpriseDescriptionContent()}
      </SectionWrapper>
    </>
  );
};

export default EnterpriseDescriptionMobile;