import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { Formik, Field, Form, useFormik} from 'formik';
import { bindActionCreators } from "redux";
import AppContext from "components/app_context";
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {createInvoice} from 'actions/invoice';
import { useDispatch } from "react-redux";
import { useCart } from "../Context";
import * as locationActions from "actions/location";
import _, { debounce } from "lodash";
import { toastError } from "helpers/toastHelper";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { toastErrorLogin } from '../../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';
import useSearchMap from "../../../hooks/useSearchMap";
import { Button } from 'react-bootstrap';
import PropTypes from "prop-types";
function UserInformation(props) {
  const {
    current_user
  } = useContext(AppContext);
  const formikRef = useRef();
  const {listProductInCarts, handleRemoveProduct, handleRemoveAllCart} = useCart();
  const productsWithBusinessId = listProductInCarts.filter(product => product.business_association_id);
  const productsWithoutBusinessId = listProductInCarts.filter(product => !product.business_association_id);
  const { hiddenButton, locationActionsCreator } = props;
  const { fetchProvince, fetchDistrict, fetchWard } = locationActionsCreator;
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [ward, setWard] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const { data: listProvinces } = props.listProvinces;
  const { data: listDistricts } = props.listDistricts;
  const { data: listWards } = props.listWards;
  const{ handleAutoComplete } = useSearchMap()
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const ulRef = useRef(null);

  useEffect(() => {
    if(current_user) {
      const initialValues = {
        name: current_user?.name || '',
        phone_number: current_user?.phone || '',
        shipping_address: current_user?.address|| '',
        email: current_user?.email || '',
        payment_type: 'Tiền mặt',
        province_id: current_user?.province_id || '',
        district_id: current_user?.district_id || '',
        ward_id: current_user?.ward_id || '',
      }
      setProvince(current_user?.province_id)
      setSelectedProvince(current_user?.province_id);
      setDistrict(current_user?.district_id)
      setSelectedDistrict(current_user?.district_id);
      setWard(current_user?.ward_id)
      formikRef.current.setValues(initialValues);
    }else{
      formikRef.current.setValues({
        name: '',
        email: '',
        phone_number: '',
        shipping_address: '',
        payment_type: 'Tiền mặt',
        province_id: '',
        district_id: '',
        ward_id: '',
      })
    }
  }, [current_user]);

  useEffect(() => {
    fetchProvince();
    fetchDistrict({ province_id: province });
    fetchWard({ district_id: district });
  }, [fetchProvince, province, fetchDistrict, fetchWard]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validate = Yup.object().shape({
    name: Yup.string().required('Tên không được bỏ trống'),
    phone_number: Yup.string().matches(/^0[1-9]\d{8}$/, 'Số điện thoại không hợp lệ').required('Số điện thoại không được bỏ trống'),
    shipping_address: Yup.string().required('Địa chỉ giao hàng là bắt buộc'),
    email: Yup.string().email('Email không đúng định dạng').matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Email không đúng định dạng')
  });

  async function createOrder(values) {
    const newArrayItemBI = productsWithBusinessId.filter(product =>product.user_id === current_user?.id).map(({id, shop_id, quantity}) => ({
      product_id: id,
      shop_id: shop_id,
      quantity: quantity,
    }));
    const newArrayItemWBI = productsWithoutBusinessId.filter(product =>product.user_id === current_user?.id).map(({id, shop_id, quantity}) => ({
      product_id: id,
      shop_id: shop_id,
      quantity: quantity,
    }));

    const newArrayItemFormattedBI = _.groupBy(newArrayItemBI, (o) => { return o.shop_id})
    const newArrayItemFormattedWBI = _.groupBy(newArrayItemWBI, (o) => { return o.shop_id})
    switch (true){
      case !current_user: {
        toastErrorLogin(isMobile)
        // toastError("Bạn chưa đăng nhập")
        break;
      }
      case listProductInCarts.length === 0: {
        toastError("Giỏ hàng trống")
        break;
      }
      default:
        Object.entries(newArrayItemFormattedBI).forEach(async([key, value]) => {
          const params = ({...values, [`items`]: [...value], [`shop_id`]: key, district_id: district, province_id: province, ward_id: ward});
          await dispatch(createInvoice(params));
        });
        Object.entries(newArrayItemFormattedWBI).forEach(async([key, value]) => {
          const params = ({...values, [`items`]: [...value], [`shop_id`]: key, district_id: district, province_id: province, ward_id: ward});
          await dispatch(createInvoice(params));
        });

        await Swal.fire(
          'Cám ơn bạn!',
          'Đặt hàng thành công',
          'success'
        ).then((result) => {
          if(result.isConfirmed){
            navigate('/nguoi-dung/don-hang-cua-toi');
          }
        })
        handleRemoveAllCart()
    }
  }

  const handleSelecteProvince = (event) => {
    const selectedId = event.target.value;
    setProvince(selectedId); // Trigger the action
  }

  const handleSelecteDistrict = (event) => {
    const selectedId = event.target.value;
    setDistrict(selectedId);
    fetchWard({ district_id: selectedId})
  }

  const debouncedApiCall = useCallback(
    debounce(async (value) => {
      const response = await handleAutoComplete(value);
      setSuggestions(response);
    }, 300),
    []
  );

  const handleKeyUp = async (e) => {
    setShowSuggestions(true);
    if (e.currentTarget.value !== "") {
      debouncedApiCall(e.currentTarget.value)
    }
  }

  const handleItemClick = async (item) => {
    formikRef.current.setFieldValue('shipping_address', item.label)
    const recordMatchingRegion = listProvinces.find((province) =>
      item.region.includes(province.name)
    );
    //Gán giá trị cho provinve
    setProvince(recordMatchingRegion?.id);
    formikRef.current.setFieldValue('province_id', recordMatchingRegion?.id)
    setSuggestions([])
  }

  const handleClickOutside = (event) => {
    if (ulRef.current && !ulRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <Formik initialValues={{
          name: '',
          email: '',
          phone_number: '',
          shipping_address: '',
          payment_type: '',
          province_id: '',
          district_id: '',
          ward_id: '',
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          createOrder(values);
        }}
        innerRef={formikRef}
        >
        {({errors, touched}) => (
          <Form>
            <div className="user-information mt-3">
              <div className="row">

                <div className="col-md-12 col-sm-12">
                  <label htmlFor="name">Tên:</label>
                  <div className="name">
                    <Field
                      className="form-control"
                      placeholder="Tên người mua"
                      id="name"
                      name="name"
                    />
                    {errors.name && touched.name && <label className="alert-invalid">{errors.name}</label>}
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Địa chỉ:</label>
                  <div className="address-shipping position-relative">
                    <Field className="form-control" placeholder="Địa chỉ giao hàng" name="shipping_address" onKeyUp={(e) => handleKeyUp(e)}/>
                    {showSuggestions && suggestions.length > 0 && (
                      <ul ref={ulRef} className="suggestions suggestions-address-invoice">
                        {suggestions.map(item => (
                          <li key={item.id} onClick={() => handleItemClick(item)}>
                            <span>{item.label}</span>
                          </li>
                        ))}
                      </ul>
                      )}
                    {errors.shipping_address && touched.shipping_address && <label className="alert-invalid">{errors.shipping_address}</label>}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Tỉnh/Thành phố</label>
                  <select
                    className="form-select"
                    value={province}
                    onChange={e => handleSelecteProvince(e)}
                  >
                    {' '}
                    {listProvinces.map(function (province) {
                      return (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Quận/Huyện</label>
                  <select
                    className="form-select"
                    value={district}
                    onChange={e => handleSelecteDistrict(e)}
                    // selected={listDistrict[0].id}
                  >
                    <option value="">Chọn quận huyện</option>
                    {listDistricts.map(function (district) {
                      return (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Phường/Xã</label>
                  <select
                    className="form-select"
                    defaultValue=""
                    onChange={e => setWard(e.target.value)}
                  >
                    <option value="">Chọn phường xã</option>
                    {listWards.map(function (ward) {
                      return (
                        <option key={ward.id} value={ward.id}>
                          {ward.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Loại thanh toán:</label>
                  <div className="payment-type">
                    <Field className="form-control"  name="payment_type" disabled/>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Email:</label>
                  <div className="email">
                    <Field
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                    {errors.email && touched.email && <label className="alert-invalid">{errors.email}</label>}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Số điện thoại:</label>
                  <div className="phone">
                    <Field
                      className="form-control"
                      placeholder="Số điện thoại"
                      type="text"
                      name="phone_number"
                    />
                    {errors.phone_number && touched.phone_number && <label className="alert-invalid">{errors.phone_number}</label>}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <Button variant="danger" type="submit" disabled={hiddenButton}>
                Mua hàng
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserInformation.propTypes = {
  hiddenButton: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => {
  return {
    listProvinces: state.location.listProvinces,
    listDistricts: state.location.listDistricts,
    listWards: state.location.listWards,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    locationActionsCreator: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);
