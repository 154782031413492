import React, { useEffect, useState }from "react";
import LeftSidebar from "../../components/left_sidebar";
import AddMetaTags from "../../components/add_meta_tags";
import { Card } from "react-bootstrap";
import ViettelPost from "./components/ViettelPost";
import GiaoHangTietKiem from "./components/GiaoHangTietKiem";
import { get_user } from 'lib';
import { getListShipping, updateConnectionStatus } from "../../apis/shipping";
import { toastSuccess, toastError} from 'helpers/toastHelper';


const ShippingUnitIndex = () => {

  const [isOpenVTP, setIsOpenVTP] = useState(false);
  const [isOpenGHTK, setIsOpenGHTK] = useState(false);
  const [isToggledVTP, setIsToggledVTP] = useState(false);
  const [isToggledGHTK, setIsToggledGHTK] = useState(false);
  const current_user_lib = get_user();

  const checkListShipping = async () => {
    const { data } = await getListShipping(current_user_lib?.shop?.id);
    data.data.forEach((element, index) => {
      if (element?.shipping_code?.toLowerCase() === 'ghtk' && element?.available_status === "active") {
        setIsToggledGHTK(true)
      }
      if (element?.shipping_code?.toLowerCase() === 'vtp' && element?.available_status === "active") {
        setIsToggledVTP(true)
      }
    })
  }

  const handleToggleVTP = (shipping_id) => {
    setIsToggledVTP(!isToggledVTP);
    updateConnection(!isToggledVTP, shipping_id, 'vtp')
  };

  const handleToggleGHTK = (shipping_id) => {
    setIsToggledGHTK(!isToggledGHTK);
    updateConnection(!isToggledGHTK, shipping_id, 'ghtk')
  };

  const handleClickArrowVTP = () => {
    setIsOpenVTP(prevState => !prevState);
  };

  const handleClickArrowGHTK = () => {
    setIsOpenGHTK(prevState => !prevState);
  };

  const updateConnection = (status, shipping_id, type) => {
    const params = {
      shipping_id: shipping_id,
      shop_id: current_user_lib?.shop?.id,
      status: status === true ? 'active' : 'inactive'
    }
    updateConnectionStatus(params).then((res) => {
      if(res.data.success) {
        toastSuccess(res.data.data)
      } else {
        if(type === 'ghtk'){
          setIsToggledGHTK(res.success);
        }else {
          setIsToggledVTP(res.success);
        }
        toastError(res.data)
      }
    })
    .catch((error) => {
      toastError(error);
    });
  }

  useEffect(() => {
    checkListShipping()
  }, [])

  return (
    <div>
      <AddMetaTags title={"Thiết lập đơn vị vận chuyển"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center saved-items">
            <Card className="p-0 mt-3">
              <Card.Body className="custom-card-body">
                <div>Thiết lập đơn vị vận chuyển <span className="red-clr">*</span></div>
                <div>
                  <ViettelPost 
                    handleToggle={handleToggleVTP}
                    isToggled={isToggledVTP}
                    isOpen={isOpenVTP}
                    current_user={current_user_lib}
                    shopId={current_user_lib?.shop?.id}
                    handleClickArrow={handleClickArrowVTP} />
                  <GiaoHangTietKiem 
                    handleToggle={handleToggleGHTK}
                    isToggled={isToggledGHTK}
                    isOpen={isOpenGHTK}
                    shopId={current_user_lib?.shop?.id}
                    current_user={current_user_lib}
                    handleClickArrow={handleClickArrowGHTK} />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingUnitIndex;
