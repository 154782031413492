import * as refundInvoiceConst from "constants/refund_invoice";
import { toastError, toastSuccess } from "helpers/toastHelper";

const initialState = {
  listBuyingRefundInvoices: {
    data: [],
    error: null,
    isLoading: false,
  },
  listSellingRefundInvoices: {
    data: [],
    isLoading: false,
  },
  detailRefundInvoice: {
    type: "",
    data: [],
    isLoading: false,
    error: null,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES: {
      return {
        ...state,
        listBuyingRefundInvoices: {
          data: initialState.listBuyingRefundInvoices.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listBuyingRefundInvoices: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listBuyingRefundInvoices: {
          data: initialState.listBuyingRefundInvoices.data,
          isLoading: true,
          error: error,
        },
      };
    }
    case refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES: {
      return {
        ...state,
        listSellingRefundInvoices: {
          data: initialState.listSellingRefundInvoices.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listSellingRefundInvoices: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listSellingRefundInvoices: {
          data: initialState.listSellingRefundInvoices.data,
          isLoading: true,
          error: error,
        },
      };
    }
    case refundInvoiceConst.FETCH_DETAIL_REFUND_INVOICE: {
      return {
        ...state,
        detailRefundInvoice: {
          data: initialState.detailRefundInvoice.type,
          error: null,
        },
      };
    }
    case refundInvoiceConst.FETCH_DETAIL_REFUND_INVOICE_SUCCESS: {
      const { data } = action.payload;
      const type = data.type;
      const detail = data.detail;
      return {
        ...state,
        detailRefundInvoice: {
          data: detail.data,
          isLoading: false,
          type: type,
          error: null,
        },
      };
    }

    case refundInvoiceConst.REQUEST_COMPLAINT_STATUS: {
      return {
        ...state,
        detailRefundInvoice: {
          data: initialState.detailRefundInvoice.data,
          isLoading: true,
          type: initialState.detailRefundInvoice.type,
          error: null,
        },
      };
    }

    case refundInvoiceConst.REQUEST_COMPLAINT_STATUS_SUCCESS: {
      const {data} = action.payload;
      toastSuccess("Yêu cầu trả hàng đã được gửi cho người bán");
      return {
        ...state,
        detailRefundInvoice: {
          data: data.data,
          isLoading: false,
          type: initialState.detailRefundInvoice.type,
          error: null,
        },
      };
    }

    case refundInvoiceConst.REQUEST_COMPLAINT_STATUS_FAILED: {
      const {error} = action.payload;
      toastSuccess(error);
      return {
        ...state,
        detailRefundInvoice: {
          data: data.data,
          isLoading: false,
        }
      };
    }
    case refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE: {
      return {
        ...state,
        detailRefundInvoice: {
          data: initialState.detailRefundInvoice.data,
          isLoading: true,
          type: initialState.detailRefundInvoice.type,
          error: null,
        },
      };
    }

    case refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE_SUCCESS: {
      const {data} = action.payload;
      toastSuccess("Cập nhật đơn hàng thành công");
      return {
        ...state,
        detailRefundInvoice: {
          data: data.data,
          isLoading: false,
          type: initialState.detailRefundInvoice.type,
          error: null,
        },
      };
    }

    case refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE_FAILED: {
      const { error } = action.payload;
      const errorText = error || 'Đã xảy ra lỗi không xác định';
      toastError(errorText);
      return {
        ...state,
        detailRefundInvoice: {
          data: initialState.detailRefundInvoice.data || null,
          isLoading: false,
          error: errorText,
        }
      };
    }

    default:
      return state;
  }
};
export default reducer;
