import React, { useContext } from "react";
import NewsFeedHeader from "./Components/NewsFeedHeader";
import NewFeedActions from "./Components/NewFeedActions";
import NewFeedComments from "./Components/NewFeedComments";
import CommentInput from "./Components/CommentInput";
import PreviewAttachment from "./Components/PreviewAttachment";
import { Card } from "react-bootstrap";
import ModalReportImage from "./Components/ModalReportImage";

const NewsFeed = () => {
  return (
    <Card>
      <Card.Body></Card.Body>
    </Card>
  );
};

export { NewsFeedHeader, NewFeedActions, NewFeedComments, CommentInput, PreviewAttachment, ModalReportImage };

export default NewsFeed;
