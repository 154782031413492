import * as ttsType from 'constants/tts';

export const fetchVoice = params => {
  return {
    type: ttsType.FETCH_VOICE,
    payload: {
      params,
    },
  };
};

export const fetchVoiceSuccess = data => {
  return {
    type: ttsType.FETCH_VOICE_SUCCESS,
    payload: {
      data,
    },
  };
} 

export const fetchVoiceFailed = error => {
  return {
    type: ttsType.FETCH_VOICE_FAILED,
    payload: {
      error,
    },
  };
}

export const resetVoice = () => {
  return {
    type: ttsType.RESET_VOICE,
  }
}