// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comment {
  position: relative;
}
.Comment .DirectionLine {
  position: absolute;
  left: 26px;
  top: 55px;
  width: 2px;
  background-color: #e4e4e4;
  height: calc(100% - 65px);
}
.Comment:hover .OptionButton {
  visibility: visible;
}
.Comment .CommentContentBox {
  background-color: #f3f3f3;
  flex-grow: 1;
  border-radius: 15px;
  padding: 10px 15px;
}
.Comment .OptionButton {
  visibility: hidden;
  font-size: 4px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Comment .OptionButton:hover {
  background-color: #f3f3f3;
  border-radius: 50%;
}
.Comment .OptionButton:after {
  content: none;
}
.Comment .CommentActions {
  padding: 0 5px;
}
.Comment .CommentActions .CommentActionsText {
  margin: 0 5px;
  font-size: 12px;
  cursor: pointer;
}
.Comment .CommentActions .CommentActionsText.active {
  color: #009a59 !important;
  font-weight: 600;
}
.Comment .CommentActions .CommentActionsText:hover {
  color: #009a59 !important;
}
.Comment .CommentActions .CommentActionsText.loading {
  cursor: wait;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsFeed/Components/Comment.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;EACA,yBAAA;AACJ;AACE;EACE,mBAAA;AACJ;AACE;EACE,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACE;EACE,kBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAAI;EACE,yBAAA;EACA,kBAAA;AAEN;AAAI;EACE,aAAA;AAEN;AACE;EACE,cAAA;AACJ;AACI;EACE,aAAA;EACA,eAAA;EACA,eAAA;AACN;AAAM;EACE,yBAAA;EACA,gBAAA;AAER;AAAM;EACE,yBAAA;AAER;AAAM;EACE,YAAA;AAER","sourcesContent":[".Comment {\n  position: relative;\n\n  .DirectionLine {\n    position: absolute;\n    left: 26px;\n    top: 55px;\n    width: 2px;\n    background-color: #e4e4e4;\n    height: calc(100% - 65px);\n  }\n  &:hover .OptionButton {\n    visibility: visible;\n  }\n  .CommentContentBox {\n    background-color: #f3f3f3;\n    flex-grow: 1;\n    border-radius: 15px;\n    padding: 10px 15px;\n  }\n  .OptionButton {\n    visibility: hidden;\n    font-size: 4px;\n    padding: 5px;\n    margin: 5px;\n    cursor: pointer;\n    width: 30px;\n    height: 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    &:hover {\n      background-color: #f3f3f3;\n      border-radius: 50%;\n    }\n    &:after {\n      content: none;\n    }\n  }\n  .CommentActions {\n    padding: 0 5px;\n\n    .CommentActionsText {\n      margin: 0 5px;\n      font-size: 12px;\n      cursor: pointer;\n      &.active {\n        color: #009a59 !important;\n        font-weight: 600;\n      }\n      &:hover {\n        color: #009a59 !important;\n      }\n      &.loading {\n        cursor: wait;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
