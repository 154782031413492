// VoiceResult.jsx
import Card from 'react-bootstrap/Card';
import AudioPlayer from 'react-h5-audio-player';
import { useSelector } from 'react-redux';

const VoiceResult = () => {
  const { data, isLoading} = useSelector(state => state.tts.voice);

  return (
    <Card>
      <Card.Header>Kết quả</Card.Header>
      {isLoading && <div className="text-center fw-bold">Đang tạo giọng đọc...</div>}
      {data &&
        <Card.Body>
          <AudioPlayer
            hasDefaultKeyBindings={true}
            src={data}
            showSkipControls={false}
            showJumpControls={false}
            layout="horizontal-reverse"
            showFilledVolume={false}
            loop={false}
            autoPlay={false}
          />
        </Card.Body>
       }
    </Card>
  );
};

export default VoiceResult;