import {
  FETCH_POST_DETAIL,
  FETCH_POST_DETAIL_SUCCESS,
  FETCH_POST_DETAIL_FAILED,
  LIKE_POST_DETAIL,
  LIKE_POST_DETAIL_SUCCESS,
  FETCH_COMMENT_POST_DETAIL_SUCCESS,
  FETCH_COMMENT_POST_DETAIL,
  LIKE_COMMENT_POST_DETAIL,
  LIKE_COMMENT_POST_DETAIL_SUCCESS,
  FETCH_SUBCOMMENT_POST_DETAIL,
  FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS,
  COMMENT_POST_DETAIL,
  COMMENT_POST_DETAIL_SUCCESS,
  UPDATE_COMMENT_WITH_PATTERN,
  FETCH_COMMENT_POST_DETAIL_FAIL,
  MODIFY_REPLY_DATA,
  MODIFY_EDIT_DATA,
  EDIT_COMMENT_POST_DETAIL,
  EDIT_COMMENT_POST_DETAIL_SUCCESS,
  DELETE_COMMENT_POST_DETAIL,
  DELETE_COMMENT_POST_DETAIL_SUCCESS,
  DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS,
  EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS,
  FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS,
  FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS,
} from 'constants/postDetail';

import merge from 'lodash/merge';
import set from 'lodash/set';
import { get, indexOf, omit } from 'lodash';

const initialState = {
  post: {
    data: null,
    postDisplay: '',
    isLoading: true,
  },
  comments: {
    replyData: {
      replierId: null,
      parentId: null,
      id: null,
    },
    editData: {
      replierId: null,
      parentId: null,
      id: null,
    },
    isLoading: false,
    meta: {
      nextPage: 1,
      classable_id: null,
      classable_type: null,
      displayLoadmore: true,
    },
    entity: {},
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POST_DETAIL:
      return {
        ...state,
        post: {
          ...state.post,
          isLoading: true,
        },
      };
    case FETCH_POST_DETAIL_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          isLoading: false,
          ...action.payload,
        },
      };
    case FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS:
      return {
        ...state,
        post: {
          data: action.payload.data,
          postDisplay: { ...state.post.postDisplay },
          isLoading: false,
        },
      };
    case FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS:
      return {
        ...state,
        post: {
          data: action.payload.data,
          postDisplay: { ...state.post.postDisplay },
          isLoading: false,
        },
      };
    case 'MODIFY_POST_DISPLAY':
      return {
        ...state,
        post: {
          ...state.post,
          postDisplay: action.payload,
        },
      };
    case 'MODIFY_META_COMMENT':
      return {
        ...state,
        comments: {
          ...state.comments,
          meta: {
            ...state.comments.meta,
            ...action.payload,
          },
        },
      };
    case FETCH_COMMENT_POST_DETAIL:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: true,
        },
      };
    case FETCH_COMMENT_POST_DETAIL_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: merge({}, state.comments.entity, action.payload.entity),
          meta: {
            ...state.comments.meta,
            nextPage: state.comments.meta.nextPage + 1,
          },
          isLoading: false,
        },
      };

    case 'RESET_DATA':
      return {
        ...state,
        ...initialState,
      };
    case FETCH_COMMENT_POST_DETAIL_FAIL:
      return {
        ...state,
        comments: {
          ...state.comments,
          meta: {
            ...state.comments.meta,
            displayLoadmore: false,
          },
          isLoading: false,
        },
      };

    case FETCH_SUBCOMMENT_POST_DETAIL:
      const commentIndex = `comment-${action.payload.id}`;
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`${commentIndex}`]: {
              ...state.comments.entity[`${commentIndex}`],
              extraData: {
                ...state.comments.entity[`${commentIndex}`].extraData,
                loading: true,
              },
            },
          },
        },
      };

    case FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`comment-${action.payload.id}`]: {
              ...state.comments.entity[`comment-${action.payload.id}`],
              children: merge({}, state.comments.entity[`comment-${action.payload.id}`].children, action.payload.data),
              extraData: {
                ...state.comments.entity[`comment-${action.payload.id}`].extraData,
                loading: false,
              },
            },
          },
        },
      };

    case LIKE_POST_DETAIL_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          data: {
            ...state.post.data,
            user_liked: !state.post.data.user_liked,
          },
        },
      };

    case LIKE_COMMENT_POST_DETAIL_SUCCESS:
      set(state.comments.entity, action.payload.pattern, !get(state.comments.entity, action.payload.pattern));
      const patternParts = action.payload.pattern.split('.');
      const commentKey = patternParts[0];
      const caseAction = patternParts[1];
      const comment = state.comments.entity[commentKey];
      comment.total_likes += caseAction === 'user_disliked' ? -1 : 1;
      comment.user_liked = caseAction !== 'user_disliked';
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [commentKey]: {
              ...comment,
            },
          },
        },
      };

    case UPDATE_COMMENT_WITH_PATTERN:
      set(state.comments.entity, action.payload.pattern, action.payload.data);
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
          },
        },
      };
    case COMMENT_POST_DETAIL:
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: Object.assign({
            ...state.comments.entity,
            // 'new': action.payload.tempComment
          }),
        },
      };
    case COMMENT_POST_DETAIL_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: Object.assign({
            ...state.comments.entity,
            [`comment-${action.payload.data.id}`]: {
              ...action.payload.data,
              children: {},
            },
          }),
        },
      };

    case MODIFY_REPLY_DATA:
      return {
        ...state,
        comments: {
          ...state.comments,
          replyData: {
            ...state.comments.replyData,
            ...action.payload,
          },
        },
      };
    case MODIFY_EDIT_DATA: {
      return {
        ...state,
        comments: {
          ...state.comments,
          editData: {
            ...state.comments.editData,
            ...action.payload,
          },
        },
      };
    }
    case EDIT_COMMENT_POST_DETAIL: {
      const commentIndex = `comment-${action.payload.params.id}`;
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`${commentIndex}`]: {
              ...state.comments.entity[`${commentIndex}`],
              extraData: {
                ...state.comments.entity[`${commentIndex}`].extraData,
                loading: true,
              },
            },
          },
        },
      };
    }

    case EDIT_COMMENT_POST_DETAIL_SUCCESS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`comment-${action.payload.id}`]: {
              ...action.payload,
              children: merge({}, state.comments.entity[`comment-${action.payload.id}`].children, action.payload.data),
              extraData: {
                ...state.comments.entity[`comment-${action.payload.id}`].extraData,
                loading: false,
              },
            },
          },
        },
      };
    }
    case DELETE_COMMENT_POST_DETAIL_SUCCESS: {
      const commentIndex = `comment-${action.payload.id}`;
      const data = omit({ ...state.comments.entity }, commentIndex);
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: data,
        },
      };
    }
    case DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS: {
      const commentIndex = `comment-${action.payload.parentId}`;
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`${commentIndex}`]: {
              ...state.comments.entity[commentIndex],
              children: action.payload.data,
              extraData: {
                ...state.comments.entity[commentIndex].extraData,
                loading: false,
              },
            },
          },
        },
      };
    }
    case EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS: {
      const commentIndex = `comment-${action.payload.parentId}`;
      //========================
      //
      return {
        ...state,
        comments: {
          ...state.comments,
          entity: {
            ...state.comments.entity,
            [`${commentIndex}`]: {
              ...state.comments.entity[commentIndex],
              children: action.payload.data,
              extraData: {
                ...state.comments.entity[commentIndex].extraData,
                loading: false,
              },
            },
          },
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
