import * as bannerConst from 'constants/banner';

export const fetchBanner = params => {
  return {
    type: bannerConst.FETCH_BANNER,
    payload: {
      params,
    },
  };
};

export const fetchBannerSuccess = data => {
  return {
    type: bannerConst.FETCH_BANNER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchBannerFailed = error => {
  return {
    type: bannerConst.FETCH_BANNER_FAILED,
    payload: {
      error,
    },
  };
};

export const saveBannerOrder = params => {
  return {
    type: bannerConst.SAVE_BANNER_ORDER,
    payload: {
      params,
    },
  };
};

export const saveBannerOrderSuccess = data => {
  return {
    type: bannerConst.SAVE_BANNER_ORDER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const saveBannerOrderFailed = error => {
  return {
    type: bannerConst.SAVE_BANNER_ORDER_FAILED,
    payload: {
      error,
    },
  };
};
