import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import PlusSign from '../../assets/images/plus.png';
import MinusSign from '../../assets/images/minus.png';

const MarketCollapse = ({ setShowMenu }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleToogleCollapse = () => setOpen(!open);

  useEffect(() => {
    if (setShowMenu) setShowMenu(false);
    if (location.pathname === '/cho-2nong') {
      setOpen(!open);
    }
  }, [location.pathname]);

  return (
    <>
      <div className=" d-flex align-items-center justify-content-between">
        <NavLink to="/cho-2nong">
          <div className="d-flex align-items-center">
            <span className="icon-farmer-market" />
            <span className="txt">Chợ 2Nông</span>
          </div>
        </NavLink>
        <div className="plus-size" onClick={handleToogleCollapse}>
          <img src={!open ? PlusSign : MinusSign} alt="open collapse" />
        </div>
      </div>

      <Collapse in={open}>
        <div className="hover bg-hover p-2 ml-4">
          <NavLink to="/cho-2nong/nong-san">
            <div className="txt mb-2">Chợ nông sản</div>
          </NavLink>
          <NavLink to="/cho-2nong/vat-tu-nong-nghiep">
            <div className="txt mb-2">Vật tư nông nghiệp</div>
          </NavLink>
          <NavLink to="/cho-2nong/si-nong-nghiep">
            <div className="txt mb-2">Chợ sỉ nông nghiệp</div>
          </NavLink>
          <NavLink to="/cho-2nong/nha-san-xuat">
            <div className="txt">Chợ sản xuất</div>
          </NavLink>
        </div>
      </Collapse>
    </>
  );
};

export default MarketCollapse;
