// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gifts .flashit {
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
  background-color: #DA251D;
  border: 0.2px solid rgba(255, 255, 255, 0.7490196078);
  padding: 0px 5px 1px;
  border-radius: 6px;
  letter-spacing: 0.5px;
  color: #fff;
}

.gifts .coming_soon {
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
  background-color: #009a59;
  border: 0.2px solid rgba(255, 255, 255, 0.7490196078);
  padding: 0px 5px 1px;
  border-radius: 6px;
  letter-spacing: 0.5px;
  color: #fff;
  height: 25px;
}

.item-lst {
  position: relative;
}

.gifts .flashit p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/GiftStore/gift.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;EACA,qDAAA;EACA,oBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;EACA,qDAAA;EACA,oBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".gifts .flashit {\n  position: absolute;\n  right: -7px;\n  top: -7px;\n  font-size: 13px;\n  font-weight: 700;\n  text-transform: none;\n  background-color: #DA251D;\n  border: 0.2px solid #ffffffbf;\n  padding: 0px 5px 1px;\n  border-radius: 6px;\n  letter-spacing: 0.5px;\n  color: #fff;\n}\n\n.gifts .coming_soon {\n  position: absolute;\n  right: -7px;\n  top: -7px;\n  font-size: 13px;\n  font-weight: 700;\n  text-transform: none;\n  background-color: #009a59;\n  border: 0.2px solid #ffffffbf;\n  padding: 0px 5px 1px;\n  border-radius: 6px;\n  letter-spacing: 0.5px;\n  color: #fff;\n  height: 25px;\n}\n.item-lst{\n  position: relative;\n}\n\n.gifts .flashit p {\n  margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
