import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppContext from "components/app_context";
import { TabContainer, TabContent, TabPane, Nav } from "react-bootstrap";
import Information from "./forms/informationForm";
import Material from "./forms/material";
import Producer from "./forms/producer";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { newTraceability, getTraceabilityById, editTraceability } from "apis/traceability";
import { toastErrorLogin } from '../../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function NewTraceability() {
  const navigate = useNavigate();
  const { current_user } = useContext(AppContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let param = searchParams.get("id");
  let [data, setData] = useState(null);
  let [isSaving, setIsSaving] = useState(false);
  let [type, setType] = useState("information");
  // thong tin
  let [name, setName] = useState("");
  let [lotCode, setLotCode] = useState("");
  let [packingWay, setPackingWay] = useState("");
  let [numberQuantity, setNumberQuantity] = useState("");
  let [certifications, setCertifications] = useState("");
  let [createdDay, setCreatedDay] = useState("");
  let [image, setImage] = useState("");
  let [code, setCode] = useState("");
  let [video, setVideo] = useState("");
  let [filevideo, setFilevideo] = useState("");
  // Nguyen lieu
  let [ingredientLand, setIngredientLand] = useState("");
  let [ingredientQuantity, setIngredientQuantity] = useState("");
  let [ingredientAddress, setIngredientAddress] = useState("");
  let [ingredientDescription, setIngredientDescription] = useState("");
  let [ingredientImage, setIngredientImage] = useState([]);
  // Nha san xuat
  let [manufactorName, setManufactorName] = useState("");
  let [manufactorAddress, setManufactorAddress] = useState("");
  let [manufactorPhone, setManufactorPhone] = useState("");
  let [manufactorEmail, setManufactorEmail] = useState("");
  let [manufactorLogo, setManufactorLogo] = useState("");

  const handleClick = () => navigate(-1);
  const handleChangeTab = (type) => setType(type);

  useEffect(() => {
    if (param) {
      getTraceabilityById(param).then((res) => {
        setData(res.data.data);
      });
    }
  }, []);

  useEffect(() => {
    const loadImageFile = (media, setter, defaultName, fileType) => {
      fetch(media)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], defaultName, { type: fileType });
          setter(file);
        });
    };
    const loadImageFiles = (mediaArray, setter, defaultName, fileType) => {
      mediaArray.forEach((media) => {
        fetch(media.name)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], defaultName, { type: fileType });
            setter((prevFiles) => [...prevFiles, file]);
          });
      });
    };
    if (data) {
      if (data.list_images.length > 0) {
        loadImageFiles(data.list_images, setImage, "traceability.jpg", "image/jpeg");
      }

      if (data.video?.name) {
        loadImageFile(data.video.name, setFilevideo, "video.mp4", "video/mp4");
      }

      if (data.ingredient.images.length > 0) {
        loadImageFiles(data.ingredient.images, setIngredientImage, "ingredient.jpg", "image/jpeg");
      }

      if (data.manufactor.images.length > 0) {
        loadImageFile(data.manufactor.images[0].name, setManufactorLogo, "logo.jpg", "image/jpeg");
      }

      initializeFields();
    }
  }, [data]);

  const initializeFields = () => {
    const {
      name,
      lot_code,
      packing_way,
      number_of_quarantine_days,
      certification,
      date_of_manufacture,
      video,
      code,
      ingredient,
      manufactor,
    } = data;

    setName(name);
    setLotCode(lot_code);
    setPackingWay(packing_way !== "null" ? packing_way : "");
    setNumberQuantity(number_of_quarantine_days > 0 ? number_of_quarantine_days : "");
    setCertifications(certification !== "null" ? certification : "");
    setCreatedDay(new Date(date_of_manufacture));
    setVideo(video?.name);
    setCode(code);
    setIngredientLand(ingredient?.land_area > 0 ? ingredient.land_area : "");
    setIngredientQuantity(ingredient?.ingredient_quantily > 0 ? ingredient.ingredient_quantily : "");
    setIngredientAddress(ingredient?.ingredient_address !== "null" ? ingredient.ingredient_address : "");
    setIngredientDescription(ingredient?.ingredient_note !== "null" ? ingredient.ingredient_note : "");
    setManufactorName(manufactor?.manufacture_name !== "null" ? manufactor.manufacture_name : "");
    setManufactorAddress(manufactor?.manufacture_address !== "null" ? manufactor.manufacture_address : "");
    setManufactorPhone(manufactor?.manufacture_phone !== "null" ? manufactor.manufacture_phone : "");
    setManufactorEmail(manufactor?.manufacture_email !== "null" ? manufactor.manufacture_email : "");
  };
  const fieldData = {
    // thong tin
    name: name,
    lot_code: lotCode,
    packing_way: packingWay,
    number_of_quarantine_days: numberQuantity,
    certification: certifications,
    date_of_manufacture: createdDay,
    "attachment[file][]": image,
    "attachment_video[file][]": filevideo,
    // nguyen liêu
    "traceability_details_attributes[0][traceability_type]": "ingredient",
    "traceability_details_attributes[0][ingredient_quantily]": ingredientQuantity,
    "traceability_details_attributes[0][ingredient_address]": ingredientAddress,
    "traceability_details_attributes[0][land_area]": ingredientLand,
    "traceability_details_attributes[0][ingredient_note]": ingredientDescription,
    "traceability_details_attributes[0][attachment][file][]": ingredientImage,
    //nha san xuât
    "traceability_details_attributes[1][traceability_type]": "manufactor",
    "traceability_details_attributes[1][manufacture_name]": manufactorName,
    "traceability_details_attributes[1][manufacture_phone]": manufactorPhone,
    "traceability_details_attributes[1][manufacture_address]": manufactorAddress,
    "traceability_details_attributes[1][manufacture_email]": manufactorEmail,
    "traceability_details_attributes[1][attachment][file][]": manufactorLogo,
  };

  const resetState = () => {
    setName("");
    setLotCode("");
    setPackingWay("");
    setNumberQuantity("");
    setCertifications("");
    setCreatedDay("");
    setImage("");
    setVideo("");
    setCode("");
    setIngredientLand("");
    setIngredientQuantity("");
    setIngredientAddress("");
    setIngredientImage("");
    setIngredientDescription("");
    setManufactorName("");
    setManufactorAddress("");
    setManufactorPhone("");
    setManufactorEmail("");
    setManufactorLogo("");
  };

  const saveData = (fieldData) => {
    if (!current_user) {
      return toastErrorLogin(isMobile)
    }
    if (name === "" || lotCode === "") {
      return toastError("Tên gọi và mã số không được bỏ trống");
    }
    setIsSaving(true);
    const formData = new FormData();
    for (const [key, value] of Object.entries(fieldData)) {
      if (value !== "") {
        if (["attachment[file][]", "traceability_details_attributes[0][attachment][file][]"].includes(key)) {
          value.forEach((file) => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, value);
        }
      }
    }
    const savePromise = data ? editTraceability({ formData }, data.id) : newTraceability({ formData });
    savePromise.then((response) => {
      setIsSaving(false);
      if (response) {
        toastSuccess("Đã lưu thành công");
        //if (!data) {
        // resetState();
        // }
      }
    });
  };
  const uploadImage = (e, tab) => {
    let files = e.target.files;
    let selectImages = Array.from(files);
    switch (tab) {
      case "info":
        setImage((prevImages) => {
          const newImages = [...prevImages, ...selectImages];
          if (newImages.length > 2) {
            toastError("Tối đa chỉ 2 ảnh");
            return prevImages; // Return the previous images if the length exceeds 2
          }
          return newImages;
        });
        break;
      case "ingredient":
        setIngredientImage((prevImages) => {
          const newImages = [...prevImages, ...selectImages];
          if (newImages.length > 2) {
            toastError("Tối đa chỉ 2 ảnh");
            return prevImages; // Return the previous images if the length exceeds 2
          }
          return newImages;
        });
        break;
      case "manufactor":
        setManufactorLogo(selectImages[0]);
        break;
      default:
        return false;
    }
  };
  const removeImage = (file, tab) => {
    switch (tab) {
      case "info":
        setImage((image) => image.filter((item) => item.lastModified !== file.lastModified));
        break;
      case "ingredient":
        setIngredientImage((ingredientImage) =>
          ingredientImage.filter((item) => item.lastModified !== file.lastModified)
        );
        break;
      case "manufactor":
        setManufactorLogo("");
        break;
      default:
        return;
    }
  };
  return (
    <>
      <AddMetaTags title={"Truy xuất nguồn gốc"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container traceability-home">
              <div className="row">
                <h5>
                  <button
                    className="border-0   bg-white text-dark"
                    onClick={handleClick}
                    type="button"
                    style={{ fontSize: "30px" }}
                  >
                    &lsaquo;
                  </button>
                  Thông tin truy xuất
                </h5>
              </div>
              <div className="row">
                <TabContainer defaultActiveKey="information" onSelect={(type) => handleChangeTab(type)}>
                  <Nav as="ul" className="nav nav-mkt-price mb-1 mt-0" id="mkt-price-tab" justify>
                    <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                      <Nav.Link as="button" eventKey="information">
                        Thông tin
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                      <Nav.Link as="button" eventKey="material">
                        Nguyên liệu
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                      <Nav.Link as="button" eventKey="producer">
                        Nhà sản xuất
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <TabContent>
                    <TabPane eventKey="information" title="Thông tin" type="information">
                      <Information
                        setName={setName}
                        setLotCode={setLotCode}
                        setPackingWay={setPackingWay}
                        setNumberQuantity={setNumberQuantity}
                        setCertifications={setCertifications}
                        setCreatedDay={setCreatedDay}
                        setVideo={setVideo}
                        image={image}
                        video={video}
                        name={name}
                        lotCode={lotCode}
                        packingWay={packingWay}
                        numberQuantity={numberQuantity}
                        certifications={certifications}
                        createdDay={createdDay}
                        code={code}
                        setFilevideo={setFilevideo}
                        uploadImage={uploadImage}
                        removeImage={removeImage}
                      />
                    </TabPane>
                    <TabPane eventKey="material" title="Nguyên liệu" type="material">
                      <Material
                        setIngredientLand={setIngredientLand}
                        setIngredientQuantity={setIngredientQuantity}
                        setIngredientAddress={setIngredientAddress}
                        setIngredientDescription={setIngredientDescription}
                        ingredientImage={ingredientImage}
                        ingredientLand={ingredientLand}
                        ingredientQuantity={ingredientQuantity}
                        ingredientAddress={ingredientAddress}
                        ingredientDescription={ingredientDescription}
                        uploadImage={uploadImage}
                        removeImage={removeImage}
                      />
                    </TabPane>
                    <TabPane eventKey="producer" title="Nhà sản xuất" type="producer">
                      <Producer
                        setManufactorName={setManufactorName}
                        setManufactorAddress={setManufactorAddress}
                        setManufactorPhone={setManufactorPhone}
                        setManufactorEmail={setManufactorEmail}
                        manufactorLogo={manufactorLogo}
                        manufactorName={manufactorName}
                        manufactorAddress={manufactorAddress}
                        manufactorEmail={manufactorEmail}
                        manufactorPhone={manufactorPhone}
                        uploadImage={uploadImage}
                        removeImage={removeImage}
                      />
                    </TabPane>
                  </TabContent>
                </TabContainer>
                <div className="row">
                  {!isSaving ? (
                    <button
                      type="button"
                      className="save-btn btn-lg btn-block code-box"
                      onClick={() => saveData(fieldData)}
                    >
                      Lưu
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="save-btn btn-lg btn-block code-box"
                      disabled
                      style={{ backgroundColor: "#ccc" }}
                    >
                      Đang lưu
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewTraceability;
