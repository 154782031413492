// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewFeedHeader {
  width: 100%;
  display: flex;
}
.NewFeedHeader .not-follow {
  background-color: white;
  border: 1px solid #009858;
  text-align: center;
  color: #191919;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 8px;
  border-radius: 10px;
  margin-left: 6px;
  position: relative;
  min-width: 40px;
  height: 25px;
  margin-left: 0px;
}
.NewFeedHeader .follow {
  background-color: #009858;
  border: 1px solid #fadf9a;
  text-align: center;
  color: #191919;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 8px;
  border-radius: 10px;
  margin-left: 6px;
  position: relative;
  min-width: 40px;
  height: 25px;
  margin-left: 0px;
  margin-top: 0px;
}

.NewFeedUserInfo {
  display: flex;
}
.NewFeedUserInfo .NewFeedBadge {
  font-size: 11px;
  color: #009a59;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 60px;
  border: 1px solid #009a59;
  margin-left: 2px;
}
.NewFeedUserInfo .NewFeedUserName {
  font-size: 17px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsFeed/Components/NewsFeedHeader.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AAAE;EACE,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AAEJ;AAAE;EACE,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAEJ;;AAEA;EACE,aAAA;AACF;AAAE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".NewFeedHeader {\n  width: 100%;\n  display: flex;\n  .not-follow {\n    background-color: white;\n    border: 1px solid #009858;\n    text-align: center;\n    color: #191919;\n    font-size: 12px;\n    font-weight: bold;\n    padding: 1px 8px;\n    border-radius: 10px;\n    margin-left: 6px;\n    position: relative;\n    min-width: 40px;\n    height: 25px;\n    margin-left: 0px\n  }\n  .follow{\n    background-color: #009858;\n    border: 1px solid #fadf9a;\n    text-align: center;\n    color: #191919;\n    font-size: 12px;\n    font-weight: bold;\n    padding: 1px 8px;\n    border-radius: 10px;\n    margin-left: 6px;\n    position: relative;\n    min-width: 40px;\n    height: 25px;\n    margin-left: 0px;\n    margin-top: 0px;\n  }\n}\n\n.NewFeedUserInfo {\n  display: flex;\n  .NewFeedBadge {\n    font-size: 11px;\n    color: #009a59;\n    font-weight: bold;\n    padding: 2px 8px;\n    border-radius: 60px;\n    border: 1px solid #009a59;\n    margin-left: 2px;\n  }\n  .NewFeedUserName {\n    font-size: 17px;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
