import React from 'react';
import EnterpriseInformation from './EnterpriseInformationDesktop';
import EnterpriseDescription from './EnterpriseDescriptionDesktop';

const Desktop = ({ enterprise }) => {
  return (
    <>
      <EnterpriseInformation enterprise={enterprise} />
      <EnterpriseDescription enterprise={enterprise} />
    </>
  );
};

export default Desktop;