import {
  fetchBuyingRefundInvoicesSuccess,
  fetchBuyingRefundInvoicesFailed,
  fetchSellingRefundInvoicesSuccess,
  fetchSellingRefundInvoicesFailed,
  fetchDetailRefundInvoiceSuccess,
  requestComplaintStatusFailed,
  requestComplaintStatusSuccess,
  createRefundInvoiceSuccess,
  createRefundInvoiceFailed,
  updateStatusRefundInvoiceSuccess,
  updateStatusRefundInvoiceFailed
} from "actions/refund_invoice";

import { put, call, select} from "redux-saga/effects";
import { updateStatusRefundInvoice, createRefundInvoice, listRefundInvoiceBuyer, listRefundInvoiceSeller, invoiceRefundDetail, buyerRequestComplaintStatus } from "apis/refund_invoice";
import { STATUS_CODE } from "constants";
import { toastError } from "../../helpers/toastHelper";

export function* userGetBuyingRefundInvoicesList({ payload }) {
  try {
    const { params } = payload;
    const respone = yield call(listRefundInvoiceBuyer, params);
    const { data, status } = respone;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchBuyingRefundInvoicesSuccess(data));
    } else {
      yield put(fetchBuyingRefundInvoicesFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userGetSellingRefundInvoicesList({ payload }) {
  try {
    const { params } = payload;
    const respone = yield call(listRefundInvoiceSeller, params);
    const { data, status } = respone;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSellingRefundInvoicesSuccess(data));
    } else {
      yield put(fetchSellingRefundInvoicesFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* userFetchDetailRefundInvoice({ payload }) {
  try {
    const { params } = payload;
    const { type, invoiceId } = params;
    let response;

    response = yield call(invoiceRefundDetail, params);

    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchDetailRefundInvoiceSuccess({invoiceId: invoiceId, type: type, detail: data }));
    }
  } catch (error) {}
}

export function* userRequestComplaintStatus({ payload }) {
  try {
    const { params } = payload;
    const {type, invoiceUserId} = params;
    let response;
    response = yield call(buyerRequestComplaintStatus, params);
    const { data, status} = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(requestComplaintStatusSuccess(data));
    }
  } catch (error) {
    const { params } = payload;
    const {invoiceUserId} = params;
    const response = yield call(invoiceRefundDetail, invoiceUserId);
    yield put(requestComplaintStatusFailed({errorText: error.data, data: response.data}));
  }
}

export function* userCreateRefundInvoice({ payload }) {
  try {
    const { params } = payload;
    const respone = yield call(createRefundInvoice, params);
    const { data, status } = respone;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createRefundInvoiceSuccess(data));
    } else {
      yield put(createRefundInvoiceFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdateStatusRefundInvoice({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(updateStatusRefundInvoice, params);

    // Kiểm tra xem response có giá trị hợp lệ không
    if (response && response.data) {
      const { data, status } = response;
      if (data.success || response.success) {
        yield put(updateStatusRefundInvoiceSuccess(data));
      } else {
        toastError(response.data);
      }
    } else {
      // Xử lý trường hợp response không có dữ liệu
      yield put(updateStatusRefundInvoiceFailed({ error: 'Không có dữ liệu trả về từ API' }));
    }
  } catch (error) {
    console.log('Error:', error);
    yield put(updateStatusRefundInvoiceFailed({ error })); // Gửi thông tin lỗi cho reducer
  }
}
