import axiosService from 'services/axiosService';
import { SSO_ENDPOINT, DOMAIN, PAGE, PER_PAGE } from 'constants';
import qs from "query-string";

const url = 'ssos/v1/auths';

export const login = (access_token) => {
  axiosService.setHeader('Authorization', `Bearer ${access_token}`);
  return axiosService.auth(`${SSO_ENDPOINT}/${url}/login`);
};

export const logout = (access_token) => {
  axiosService.setHeader('Authorization', `Bearer ${access_token}`);
  return axiosService.auth(`${SSO_ENDPOINT}/${url}/logout`);
};

const user_url = 'api/v2/account';
export const updateUser = (data) => {
  return axiosService.post(`${DOMAIN}/${user_url}/update_user`, data.formData);
};

export const updatePhone = (data) => {
  axiosService.setHeader('Authorization', `Bearer ${data.access_token}`);
  return axiosService.put(`${DOMAIN}/${user_url}/new_update_phone`, data.formData);
};

export const updateOtp = (data) => {
  axiosService.setHeader('Authorization', `Bearer ${data.access_token}`);
  return axiosService.post(`${DOMAIN}/${user_url}/new_check_verify_code`, data.formData);
};

export const getProfile = (id) => {
  return axiosService.get(`${DOMAIN}/${user_url}/profile/${id}`);
};

export const requestLogout = () => {
  return axiosService.get(`${SSO_ENDPOINT}/logout?format=json`);
}

export const followItem = (params) => {
  return axiosService.post(`${DOMAIN}/${user_url}/follow_item`,params);
}

export const fetchFollowing = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${user_url}/followings${query}`,true);
}

export const unFollow = (params) => {
  return axiosService.post(`${DOMAIN}/${user_url}/unfollow_item`, params);
}

export const sharePoint = (params) => {
  return axiosService.post(`${DOMAIN}/${user_url}/share_point`, params);
}

export const login_id_portal = () => {
  return axiosService.get(`${DOMAIN}/${user_url}/login_id_portal`, true);
}

export const updateCurrentPassword = (params) => {
  return axiosService.post(`${DOMAIN}/${user_url}/change_current_password`, params);
}

export const fetchFollowPosts = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${user_url}/follow_posts${query}`, true);
}
