import React from "react";
import { imageUrl } from "lib";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import moment from "moment";

function SubNews({ listSubNews }) {
  return (
    <>
      <div className="sub-news">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              {listSubNews.map(function (data, index) {
                let imageSub =
                  data.image === null
                    ? "/images/product-image-default.png"
                    : imageUrl(data.image);
                return (
                  <div
                    className="col-md-12 col-sm-6 col-xs-12 pb-1"
                    key={index}
                  >
                    <Link
                      to={`/tin-tuc/${data.slug ? data.slug : data.id}`}
                      className="row align-items-center mb-3 sub-news-item"
                    >
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <img
                          src={imageSub}
                          title="news photo"
                          className="img-cover"
                        />
                      </div>
                      <div className="col-md-8 col-sm-8 col-xs-8">
                        <h6 className="fw-semibold line-clamp-02">
                          {data.title}
                        </h6>
                        <div className="d-inline-block">
                          <span className="icon-time align-middle"></span>
                          <span>
                            {moment(data?.created_at).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="d-inline-block ml-3">
                          <span className="icon-eye-line01 align-middle"></span>
                          <span>{data.viewed}</span>
                        </div>
                        <div className="d-inline-block ml-3">
                          <span className="icon-comment-line align-middle"></span>
                          <span>{data.total_comment}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubNews;
