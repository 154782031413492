import React from "react";
import { DOMAIN } from "constants";
import { validURL } from "lib";
import "./index.scss";

const Avatar = ({ src, alt}) => {
  const imageUrl = () => {

    if (src) {
      return validURL(src) ? src : `${DOMAIN}${src}`;
    }
    return "/images/avatar.png";
  };
  return (
    <div className="avatar">
      <img src={imageUrl()} alt={alt} />
    </div>
  );
};

Avatar.defaultProps= {
  alt: '',
  src: '',
  rest: {}
}

export default Avatar;
