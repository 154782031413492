import React, { useState, useEffect } from "react";
import { TabContainer, TabContent, TabPane, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchSellingRefundInvoices } from "redux/actions/refund_invoice";
import { fetchListBusinessInvoice } from "actions/business";
import ListInvoices from "./listInvoces.jsx";
import { Link, useParams } from "react-router-dom";
import FilterInvoiceBox from "./FilterInvoiceBox.jsx";

const Tabs = () => {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const listBussinessInvoices = useSelector((state) => state.business.business_invoice.data);
  // const listBussinessRefundInvoices = useSelector((state) => state.refundInvoices.listSellingRefundInvoices.data);
  const [statusBox, setStatusBox] = useState('pending');
  const [keyword, setKeyword] = useState("");

  const handleChangeTab = () => {
    dispatch(fetchListBusinessInvoice({ businessId: businessId, page: 1, limit: 24, status: 'pending'}));
    setStatusBox("pending");
    setKeyword("");
  };

  useEffect(() => {
    dispatch(fetchListBusinessInvoice({ businessId: businessId, status: 'pending' }));
  }, [businessId]);

  const filterInvoices = (tab, keyword) => {
    setStatusBox(tab);
    setKeyword(keyword);
    dispatch(fetchListBusinessInvoice({ businessId: businessId, page: 1, limit: 24, status: tab, sku_keyword: keyword }));
  };

  const statusCheck = (string) => {
    switch (string) {
      case "delivered":
        return { text: "Đã giao hàng", color: "success" };
      case "pending":
        return { text: "Chờ xác nhận", color: "warning" };
      case "cancelled":
        return { text: "Đã hủy", color: "danger" };
      case "failed_delivery":
        return { text: "Giao thất bại", color: "danger" };
      case "returned":
        return { text: "Trả hàng", color: "warning" };
      case "ready_to_ship":
        return { text: "Chờ lấy hàng", color: "warning" };
      case "shipped":
        return { text: "Đang giao", color: "warning" };
      case "rejected":
        return { text: "Người bán từ chối đơn", color: "danger" };
      case "admin_rejected":
        return { text: "Quản trị viên từ chối đơn", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };

  const statusReturnCheck = (string) => {
    switch (string) {
      case "requested":
        return { text: "Yêu cầu đã được gửi", color: "warning" };
      case "approved":
        return { text: "Yêu cầu được chấp nhận", color: "warning" };
      case "rejected":
        return { text: "Yêu cầu bị người bán từ chối", color: "danger" };
      case "cancelled":
        return { text: "Yêu cầu bị huỷ bởi người mua", color: "danger" };
      case "shipped":
        return { text: "Hàng đã được lấy/đang vận chuyển", color: "warning" };
      case "received":
        return { text: "Hàng trả về đã nhận được", color: "success" };
      case "refunded":
        return { text: "Hoàn tiền đã được xử lý", color: "success" };
      case "completed":
        return { text: "Quá trình hoàn tất", color: "success" };
      default:
        return { text: "", color: "primary" };
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="title-line-lt text-uppercase fw-bold">
          <Link to={`/lien-ket-danh-nghiep/chuc-nang/${businessId}`}>
            <span className="d-block">Danh sách đơn hàng</span>
          </Link>
        </h5>
      </div>
      <TabContainer onSelect={(type) => handleChangeTab(type)}>
        <FilterInvoiceBox
          filter={filterInvoices}
          statusBox={statusBox}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        <TabContent>
        { statusBox === 'returned' ?
          <ListInvoices data={listBussinessInvoices} statusCheck={statusReturnCheck} businessId={businessId} />
        :
          <ListInvoices data={listBussinessInvoices} statusCheck={statusCheck} businessId={businessId}/>
        }
        </TabContent>
      </TabContainer>
    </>
  );
};

export default Tabs;
