import { saveBannerOrderSuccess, fetchBannerSuccess } from 'actions/banner';
import { put, call } from 'redux-saga/effects';
import { getPopupAdvertisements } from '../../apis/banner';

export function* sagaFetchBanner({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getPopupAdvertisements, params);
    const { data } = resp;
    yield put(fetchBannerSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* sagaSaveOrderBanner({ payload }) {
  try {
    const { params } = payload;
    yield put(saveBannerOrderSuccess(params));
  } catch (error) {
    console.log(error);
  }
}
