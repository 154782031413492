import React, { useEffect, useState, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import * as productActions from 'actions/product';
import { PAGE } from 'constants';
import AppContext from 'components/app_context';
import LeftSidebar from 'components/left_sidebar';
import AddMetaTags from 'components/add_meta_tags';

function UserProducts(props) {
  const { keyword } = useContext(AppContext);
  let { productActionsCreator } = props;
  let { fetchUserProduct } = productActionsCreator;
  let [page] = useState(PAGE);

  useEffect(() => {
    fetchUserProduct({ keyword: keyword, page: page, limit: 8, product_type: 'all' });
  }, [fetchUserProduct, keyword]);

  return (
    <div className="products">
      <AddMetaTags title={'Danh sách sản phẩm của người dùng'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    listUserProducts: state.product.listUserProducts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    productActionsCreator: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProducts);
