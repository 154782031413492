// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 45px;
  height: 45px;
}
.avatar img:hover {
  filter: brightness(110%);
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ;AADI;EACE,wBAAA;AAGN","sourcesContent":[".avatar {\n  border: 1px solid #e9e9e9;\n  border-radius: 50%;\n  overflow: hidden;\n  & img {\n    width: 45px;\n    height: 45px;\n    &:hover {\n      filter: brightness(110%);\n      // border-color: black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
