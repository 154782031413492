import React from 'react';
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import { imageUrl } from 'lib';


const ListProducts = ({ invoiceDetail }) => {
  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-12">
          <table className="table align-middle mb-0 bg-white ">
            <thead className="bg-light">
              <tr>
                <th>Tên sản phẩm</th>
                <th>Giá tiền</th>
                <th className="text-center">Số lượng</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetail && invoiceDetail?.invoice_items?.map((product) => {
                return (
                  <tr key={product?.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <Link to={`/san-pham/${product?.product_id}`}>
                          <img
                            src={product?.product_images === null || product?.product_images === undefined ? '/images/photos/no_image.png' : imageUrl(product?.product_images[0]?.name)}
                            className="rounded-circle"
                            alt=""
                            style={{ width: "45px", height: "45px" }}
                          />
                        </Link>
                        <div className="ms-3">
                        <Link to={`/san-pham/${product?.product_id}`}>
                          <p className="fw-bold mb-1">{product?.product_name}</p>
                        </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="fw-bold mb-1" style={{ marginTop: "10px"}}>
                        <NumberFormat>{product?.price}</NumberFormat>₫
                      </p>
                    </td>
                    <td className="text-center">
                      <div className="cart-quantity" style={{ marginTop: "10px"}}>
                        {product?.quantity}
                      </div>
                    </td>
                    <td>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListProducts;