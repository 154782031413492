import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from 'query-string';
import { get_user } from 'lib';
const url = 'api/v2/market_prices';
const url_account = 'api/v2/account';

export const getAllMarketPrices = (params = { page: 1 }) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true);
};

export const getMarketPrices = params => {
  const { id, ...queryParams } = params;
  let query = '';
  if (Object.keys(queryParams).length > 0) {
    query = `?${qs.stringify(queryParams)}`;
  }
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.get(`${DOMAIN}/${url}/${id}${query}`, true);
};

export const reportPriceApi = params => {
  return axiosService.post(`${DOMAIN}/${url}/user_report`, params);
};

export const contributePrice = params => {
  return axiosService.post(`${DOMAIN}/${url}`, params.formData);
};
export const likeMarketPrice = params => {
  return axiosService.post(`${DOMAIN}/${url_account}/like_item`, params);
};

export const dislikeMarketPrice = params => {
  return axiosService.post(`${DOMAIN}/${url_account}/unlike`, params);
};

export const fetchFollowingMarketPrice = params => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_account}/like_market_prices${query}`, true);
};
