import moment from 'moment';
import { useMemo } from 'react';

const useExtractPrices = (formattedData) => {
  const prices = useMemo(() => {
    return formattedData.map(item => {
      return {
        y: item.price,
        x: moment(item.created_at).format('DD-MM-YYYY'),
      }
     });
  }, [formattedData]);

  return prices;
};

export default useExtractPrices;
