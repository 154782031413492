import React, { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { formatVND } from "../Utils/FormatPrice";
import { filterAndFormatData } from "../Utils/FormatDate";

// Static options moved outside of the component
const staticOptions = {
  chart: {
    type: "area",
    stacked: false,
    height: 350,
    zoom: { type: "x", enabled: true, autoScaleYaxis: true },
    toolbar: { show: false },
  },
  colors: ["rgb(0, 154, 89)"],
  dataLabels: { enabled: false },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  yaxis: {
    min: 0,
    labels: { formatter: (val) =>  formatVND(val) },
    title: { text: "Giá" },
  },
  xaxis: { tickAmount: 6 },
  tooltip: { shared: false, y: { formatter: (val) => `${formatVND(val)}` } },
};

const ApexChartMarKetPrice = ({ chartData, marketPriceName, xLabels, xLabelReplace}) => {
  const title = useMemo(() => `GIÁ ${marketPriceName.title}`, [marketPriceName.title]);  
  const options = useMemo(() => ({
    ...staticOptions,
      xaxis: {
        type: 'category',
        categories: xLabels,
        overwriteCategories: xLabelReplace,
        tickAmount: 5, // Hiển thị tối đa 6 nhãn
        tickPlacement: 'on',
        labels: {
          rotate: -45, // Xoay nhãn để tránh chồng chéo
          rotateAlways: true, // Luôn xoay nhãn
          hideOverlappingLabels: false, // Hiển thị tất cả các nhãn, không ẩn nhãn bị chồng chéo
          style: {
            fontSize: '12px',
          },
        },
      },
    title: {
      text: title,
      align: "center",
      style: { fontSize: "16px", fontWeight: "bold", color: "#263238" },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: { height: 200 },
        legend: { position: "bottom" },
        yaxis: { labels: { show: false } },
      },
    }],
  }), [title, xLabels]);

  const [series, setSeries] = useState([{ name: "Price", data: [] }]);

  useEffect(() => {
    const formattedData = filterAndFormatData(chartData);
    // Cập nhật series dựa vào dữ liệu đã được xử lý
    const seriesData = formattedData.map((item) => item.price);
    setSeries([{ name: "Giá", data: seriesData }]);
  }, []);
  
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default ApexChartMarKetPrice;
