import React from "react";
import MainNews from "./mainNews";
import SubNews from "./subNews";

function Tabs({ newsFutures, listSubNews }) {
  return (
    <>
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="hotnews" role="tabpanel" aria-labelledby="hotnews-tab">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <MainNews newsFutures={newsFutures} />
            </div>
            <div className="col-md-5 col-sm-12">
              {/* sub news */}
              <SubNews listSubNews={listSubNews} />
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="newnews" role="tabpanel" aria-labelledby="newnews-tab">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <MainNews newsFutures={newsFutures} />
            </div>
            <div className="col-md-5 col-sm-12">
              <SubNews listSubNews={listSubNews} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabs;
