import React, { useContext } from "react";
import LeftSidebar from "components/left_sidebar";
import "react-datepicker/dist/react-datepicker.css";
import AddMetaTags from "components/add_meta_tags";
import AppContext from "components/app_context";
import { useFormik } from "formik";
import { updateCurrentPassword } from "apis/auth";
import * as authActions from "actions/auth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "helpers/toastHelper";

function UpdatePassword(props) {
  const { current_user } = useContext(AppContext);
  $('#password').on("copy paste",function(e) {
        return false;
  });
  const validate = (values) => {
    const errors = {};
    if (!values.current_password && current_user && current_user.partner_type === null) {
      errors.current_password = "Vui lòng nhập mật khẩu hiện tại";
    }
    if (!values.password) {
      
      errors.password = "Vui lòng nhập mật khẩu";
    }
    if(values.password.length > 20) {
      errors.password = "Mật khẩu không vượt quá 20 kí tự";
    }
    if(values.password.length < 6){
        errors.password = "Mật khẩu tối thiểu 6 kí tự";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Vui lòng nhập mật khẩu xác nhận";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Không trùng với mật khẩu";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      confirm_password: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      updateCurrentPassword({
        current_password: values.current_password,
        password: values.password,
        password_confirmation: values.confirm_password,
      }).then((res) => {
          if(res){
            toastSuccess("Thay đổi mật khẩu thành công");
            resetForm();
          }else{
            toastError("Mật khẩu cũ của bạn không hợp lệ");
          }
        })
        .catch((errors) => {
          toastError(errors.data.data);
        });
    },
  });

  return (
    <div className="profile">
      <AddMetaTags title={"Cập nhật mật khẩu"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <form onSubmit={formik.handleSubmit}>
            <div className="container profile-page">
              <div className="text-center inf-box">
                <div className="upload-avatar">
                  {current_user && current_user.image !== null ? (
                    <img
                      src={`${current_user && current_user.image}`}
                      alt="avatar"
                      className="avatar"
                    />
                  ) : (
                    <img
                      src="/images/avatar.png"
                      alt="avatar"
                      className="avatar"
                    />
                  )}
                  <span className="icon-camera" />
                  <input type="file" className="form-control" defaultValue="" />
                </div>
                <p className="name-txt"></p>
                <div className="user-type">
                  <span className="fst-italic text-muted">Nông dân thường</span>
                </div>
                <div className="row">
                  <div className="col-6 text-end">
                    <div className="level-box text-center d-inline-block">
                      <div className="level text-center">Chưa có hạng</div>
                      <div className="txt text-center">Xếp hạng</div>
                    </div>
                  </div>
                  <div className="col-6 text-start">
                    <div className="bonus-box text-center d-inline-block">
                      <div className="point text-center">100</div>
                      <div className="txt text-center">Điểm hiện có</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label>Mật khẩu cũ</label>
                  <input
                    className={`form-control ${
                      formik.touched.current_password &&
                      formik.errors.current_password &&
                      "border border-danger"
                    }`}
                    type="password"
                    name="current_password"
                    onChange={formik.handleChange}
                    value={formik.values.current_password}
                    onBlur={formik.handleBlur}
                    onPaste={e => e.preventDefault()}
                    disabled={current_user && current_user.partner_type === null ? false : true}
                  />
                  {formik.touched.current_password &&
                    formik.errors.current_password && (
                      <label className="alert-invalid ">
                        {formik.errors.current_password}
                      </label>
                    )}
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Mật khẩu mới</label>
                  <input
                    className={`form-control ${
                      formik.touched.password &&
                      formik.errors.password &&
                      "border border-danger"
                    }`}
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onPaste={e => e.preventDefault()}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <label className="alert-invalid ">
                      {formik.errors.password}
                    </label>
                  )}
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Nhập lại mật khẩu</label>
                  <input
                    className={`form-control ${
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password &&
                      "border border-danger"
                    }`}
                    type="password"
                    name="confirm_password"
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password}
                    onBlur={formik.handleBlur}
                    onPaste={e => e.preventDefault()}
                  />
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password && (
                      <label className="alert-invalid ">
                        {formik.errors.confirm_password}
                      </label>
                    )}
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-danger">
                  cập nhật{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    authActionsCreator: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
