// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewFeedActions {
  display: flex;
  font-size: 12px;
  border: 2px solid black;
  border-width: 1px 0 1px 0px;
  height: 50px;
}
.NewFeedActions .NewFeedActionButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.NewFeedActions .NewFeedActionButton.liked {
  color: #009a59 !important;
}
.NewFeedActions .NewFeedActionButton:hover {
  color: #009a59 !important;
  background-color: #f3f3f3;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsFeed/Components/NewFeedActions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EAEA,uBAAA;EACA,2BAAA;EACA,YAAA;AAAF;AACE;EACE,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EACE,yBAAA;AAEN;AAAI;EACE,yBAAA;EACA,yBAAA;AAEN","sourcesContent":[".NewFeedActions {\n  display: flex;\n  font-size: 12px;\n  // background-color: #f0f0f0;\n  border: 2px solid black;\n  border-width: 1px 0 1px 0px;\n  height: 50px;\n  .NewFeedActionButton {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    &.liked {\n      color: #009a59 !important;\n    }\n    &:hover {\n      color: #009a59 !important;\n      background-color: #f3f3f3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
