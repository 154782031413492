import React, { forwardRef, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Avatar from 'components/Avatar';
import useAutosizeTextArea from 'hooks/useAutosizeTextArea';
import { toastError } from 'helpers/toastHelper';
import { validateStringEditor } from '../../../lib';

const CommentInput = forwardRef(({ onSubmit }, ref) => {
  const formRef = useRef(null);
  const [formValue, setFormValue] = useState({
    comment: '',
  });
  useAutosizeTextArea(ref.current, formValue);

  const handleOnChange = e => {
    const { name, value } = e.target;
    setFormValue({ [name]: value });
  };

  const onKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      formRef.current.requestSubmit();
    }
  };

  const handleUserSubmit = async e => {
    e.preventDefault();
    const validation = validateStringEditor(formValue.comment);
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        await onSubmit(formValue);
        setFormValue({ comment: '' });
    }
  };

  return (
    <div className="p-2 d-flex cmnt-box">
      <div className="flex-grow-1">
        <Form ref={formRef} onSubmit={handleUserSubmit} className="m-0 position-relative">
          {/* <div className="me-2 position-absolute">
            <Avatar />
          </div> */}
          <InputGroup>
            <Form.Control
              as="textarea"
              ref={ref}
              autoFocus
              placeholder="Nhập bình luận..."
              name="comment"
              style={{ resize: 'none' }}
              value={formValue.comment}
              onChange={handleOnChange}
              rows={1}
              onKeyDown={onKeyDown}
            />
            <Button type="submit">Gửi</Button>
          </InputGroup>
        </Form>
      </div>
    </div>
  );
});

export default CommentInput;
