import ScrollToTop from "components/ScrollToTop";
import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";

import { PostDetailContext } from "../../containers/PostDetailProvider";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import {
  NewFeedActions,
  NewsFeedHeader,
  NewFeedComments,
  CommentInput,
} from "components/NewsFeed";

const DetailPage = () => {
  const {
    onClickActionsPost,
    onClickActionsComment,
    onVisisibleReplyForm,
    onUserComment,
    replyData,
    listComments,
    post,
    isDisplayLoadmore,
    loadMoreComment,
    currentUser
  } = useContext(PostDetailContext);
  const inputRef = useRef(null);

  return (
    <ScrollToTop>
      <div className="post-detail">
        <AddMetaTags />
        <Row>
          <LeftSidebar />
          <Col md={6} sm={12}>
            {post && (
              <Card>
                <Card.Body>
                  <div>
                    <NewsFeedHeader
                      memberRate={post.member_rate}
                      avatarUrl={post.shop_image}
                      userLeve={post.user_level}
                      userSlug={post.user.slug}
                      userName={post.user_name}
                      createdAt={Date.parse(post.created_at)}
                    />
                  </div>
                  <NewFeedActions
                    onClick={onClickActionsPost}
                    liked={post.user_liked}
                  />
                  <CommentInput ref={inputRef} onSubmit={onUserComment} />
                  <div className="p-1">
                    <NewFeedComments
                      parent={""}
                      comments={listComments}
                      setVisibilityFormReply={onVisisibleReplyForm}
                      visibilityFormReply={replyData.id}
                      handleUserInteractionComment={onClickActionsComment}
                      currentUser={currentUser}
                    />
                    {isDisplayLoadmore && (
                      <div className="px-3" onClick={loadMoreComment}>
                        Xem thêm bình luận
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </ScrollToTop>
  );
};

export default DetailPage;
