import * as articleActions from 'actions/article';
import * as postActions from 'actions/post';
import ScrollToTop from 'components/ScrollToTop';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import RightSidebar from 'components/right_sidebar';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTechnicalProcessId } from "apis/technical_process";
import { _ } from 'core-js';

function TechnicalProcessDetail(props) {
  const { technicalProcessId } = useParams();
  const article = props.articleDetail;
  // state for technical process
  const [technicalProcessDetail, setTechnicalProcessDetail] = useState({
    data: {},
    isLoading: true,
    error: false
  });

  const handleFetchTechnicalProcessDetail = async () => {
    const response = await getTechnicalProcessId(technicalProcessId);
    return response;
  }

  useEffect(() => {
    handleFetchTechnicalProcessDetail().then((response) => {
      const { data, status } = response;
      if (status === 200) {
        setTechnicalProcessDetail({
          data: data.data,
          isLoading: false,
          error: false
        })
      }
    }).catch((error) => {
      setTechnicalProcessDetail({
        data: {},
        isLoading: false,
        error: true
      })
    })
  }, technicalProcessId);

  const renderNotFoundPage = () => {
    return (
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
          <p class="fs-3"> <span class="text-danger">Lỗi!</span> Không tìm thấy quy trình kỹ thuật.</p>
          <a href="index.html" class="btn btn-primary">Quay về trang chủ</a>
        </div>
      </div>
    )
  }

  return (
    <ScrollToTop>
      <div className="detail-article">
        <AddMetaTags title={article?.title} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12">
            <div className="cont-center">
              {technicalProcessDetail?.error && (renderNotFoundPage())}
              <div className="cntry-news">
                <h3 className="mt-3">{!_.isEmpty(technicalProcessDetail?.data) && parse(technicalProcessDetail?.data.title)}</h3>
                <div className="mt-3">{!_.isEmpty(technicalProcessDetail?.data) && parse(technicalProcessDetail?.data.content)}</div>
              </div>
            </div>
          </div>
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}



export default (TechnicalProcessDetail);
