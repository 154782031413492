import React from 'react';
import ReactPlayer from 'react-player';
import {DOMAIN} from 'constants';

function MediaComponent({ mediaUrl }) {
    const isImage = mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') || mediaUrl.endsWith('.png') || mediaUrl.endsWith('.gif');
    const isVideo = mediaUrl.endsWith('.mp4');

    // Check if the media URL has a domain
    const hasDomain = mediaUrl.includes('http://') || mediaUrl.includes('https://');

    // Extend domain if media URL doesn't have a domain
    const extendedMediaUrl = hasDomain ? mediaUrl : `${DOMAIN}/${mediaUrl}`;

    if (isImage) {
        return <img src={extendedMediaUrl} alt="Media" className='bg-img position-relative'/>;
    } else if (isVideo) {
        return (
          <div style={{ width: '100%', height: '120px' }}>
              <ReactPlayer url={extendedMediaUrl} controls={false} width="100%" height="100%" className="media-component"/>
          </div>
      );
    } else {
        return <p>Unsupported media type</p>;
    }
}

export default MediaComponent;