const CREATE_INVOICE = "CREATE_INVOICE";
const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
const CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED";

export { CREATE_INVOICE, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_FAILED };

export const FETCH_BUYING_INVOICES = "FETCH_BUYING_INVOICES";
export const FETCH_BUYING_INVOICES_SUCCESS = "FETCH_BUYING_INVOICES_SUCCESS";
export const FETCH_BUYING_INVOICES_FAILED = "FETCH_BUYING_INVOICES_FAILED";

export const FETCH_SELLING_INVOICES = "FETCH_SELLING_INVOICES";
export const FETCH_SELLING_INVOICES_SUCCESS = "FETCH_SELLING_INVOICES_SUCCESS";
export const FETCH_SELLING_INVOICES_FAILED = "FETCH_SELLING_INVOICES_FAILED";

export const FETCH_DETAIL_INVOICE = "FETCH_DETAIL_INVOICE";
export const FETCH_DETAIL_INVOICE_SUCCESS = "FETCH_DETAIL_INVOICE_SUCCESS";
export const FETCH_DETAIL_INVOICE_FAILED = "FETCH_DETAIL_INVOICE_FAILED";

export const BUYER_CANCEL_ORDER = 'BUYER_CANCEL_ORDER';
export const BUYER_CANCEL_ORDER_SUCCESS = 'BUYER_CANCEL_ORDER_SUCCESS';

export const SELLER_UPDATE_ORDER_INVOICE = 'SELLER_UPDATE_ORDER_INVOICE';
export const SELLER_UPDATE_ORDER_INVOICE_SUCCESS = 'SELLER_UPDATE_ORDER_INVOICE_SUCCESS';

export const UPDATE_STATUS_INVOICE = "UPDATE_STATUS_INVOICE";
export const UPDATE_STATUS_INVOICE_OLD = "UPDATE_STATUS_INVOICE_OLD";
export const UPDATE_STATUS_INVOICE_SUCCESS = "UPDATE_STATUS_INVOICE_SUCCESS";
export const UPDATE_STATUS_INVOICE_FAILED = "UPDATE_STATUS_INVOICE_FAILED";
