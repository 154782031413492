import React from "react";
import classnames from "classnames";
import "./NewFeedActions.scss";
import { Dropdown } from "react-bootstrap";
import ButtonShareFacebook from "./../../ButtonShareFacebook/index";
import CopyUrl from "../../CopyURL/CopyUrl";

const NewFeedActions = ({ onClick, liked }) => {
  return (
    <div className="NewFeedActions">
      <div
        className={classnames("NewFeedActionButton", { liked: liked })}
        onClick={() => onClick("like")}
      >
        <span className="icon-like01 fs-5"></span>
        <span className="ms-1">Thích</span>
      </div>
      <div
        className="NewFeedActionButton"
        onClick={() => onClick("share-point")}
      >
        <span className="icon-get-gift fs-5" />
        <span className="ms-1">Tặng điểm</span>
      </div>
      <div className="NewFeedActionButton">
        <Dropdown>
          <Dropdown.Toggle as="div">
            <span className="icon-share01 fs-6"></span>
            <span className="ms-1">Chia sẻ</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            as="ul"
            className="dropdown-menu dropdown-menu-end"
            style={{ width: "12rem" }}
          >
            <Dropdown.Item as="li" className="dropdown-item">
              <div
                style={{ height: "32", width: "32", cursor: "pointer" }}
                className="mr-2"
              >
                <ButtonShareFacebook>
                  <span className="ml-1">Chia sẻ Facebook</span>
                </ButtonShareFacebook>
              </div>
            </Dropdown.Item>
            <Dropdown.Item as="li" className="dropdown-item" onClick={() => onClick("share")}>
              <div
                style={{ height: "32", width: "32", cursor: "pointer" }}
                className="mr-2"
              >
                <img
                  src="/images/logo_2nong.png"
                  alt="2Nong-share"
                  style={{
                    height: "32",
                    width: "32",
                    cursor: "pointer",
                    backgroundColor: "#009858",
                    borderRadius: "3px",
                  }}
                />
                <span className="hover ml-1">Chia sẻ diễn đàn</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item as="li" className="dropdown-item">
              <div style={{ height: "32", width: "32", cursor: "pointer" }} className="mr-2">
                <CopyUrl url={window.location.href}/>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default NewFeedActions;
