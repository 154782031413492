import * as albumsConst from 'constants/albums';
import {toastSuccess, toastError} from 'helpers/toastHelper';
import _ from 'lodash';

const initialState = {
  listAlbums: {
    data: [],
    isLoading: false,
    error: null,
    isLoadMore: false,
  },
  listAlbumsDetail: {
    data: [],
    isLoading: false,
    error: null,
    isLoadMore: false,
  },
  listImageAlbums: {
    data: [],
    isLoading: false,
    error: null,
    isLoadMore: false
  }
}
const reducer  = (state = initialState, action) => {
  switch (action.type) {
    case albumsConst.FETCH_USER_ALBUMS: {
      return {
        ...state,
        listAlbums: {
          data: initialState.listAlbums.data,
          isLoading: true,
        }
      }
    }

    case albumsConst.FETCH_USER_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listAlbums: {
          data: data.data.data,
          isLoading: false,
          error: null,
        }
      }
    }
    case albumsConst.FETCH_USER_ALBUMS_FAILED: {
      const {error} = action.payload;
      return {
        ...state, 
        listAlbums: {
          data: state.listAlbums.data,
          isLoading: false,
          error: error.data
        }
      }
    }

    case albumsConst.CREATE_AN_ALBUM_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listAlbums: {
          data: [...state.listAlbums.data, data.data],
          isLoading: false,
          error: null,
          isLoadMore: false
        }
      }
    }
    case albumsConst.FETCH_USER_ALBUMS_ID: {
      return {
        ...state,
        listAlbumsDetail: {
          data: initialState.listAlbumsDetail.data,
          isLoading: true,
          error: null,
        }
      }
    }
    case albumsConst.FETCH_USER_ALBUMS_ID_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listAlbumsDetail: {
          data: data.data,
          isLoading: false,
          error: null,
        }
      }
    }

    case albumsConst.FETCH_USER_ALBUMS_ID_FAILED: {
      const error = action.payload;
      return {
        ...state,
        listAlbumsDetail: {
          data: error.msg,
          isLoading: false,
          error: error.msg,
        }
      }
    }

    case albumsConst.FETCH_LIST_IMAGE_ALBUMS: {
      return {
        ...state,
        listImageAlbums: {
          data: initialState.listImageAlbums.data,
          isLoading: true,
          error: null,
        }
      }
    }
    case albumsConst.FETCH_LIST_IMAGE_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listImageAlbums: {
          data: data.data,
          isLoading: false,
          error: null,
        }
      }
    }
    case albumsConst.FETCH_LIST_IMAGE_ALBUMS_FAILED: {
      const {error} = action.payload;
      return {
        ...state,
        listImageAlbums: {
          data: error.msg,
          isLoading: false,
          error: error.msg,
        }
      }
    }
    case albumsConst.UPLOAD_IMAGE_TO_ALBUMS: {
      return {
        ...state, 
        listImageAlbums: {
        data: initialState.listImageAlbums.data,
        isLoading: true,
        error: null
      }}
    }
    case albumsConst.UPLOAD_IMAGE_TO_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      toastSuccess('Tải ảnh thành công');
      return {
        ...state,
        listImageAlbums: {
          data: _.sortBy([...state.listImageAlbums.data, ...data.data.images], (e) => {return e.id}).reverse(),
          isLoading: false,
          error: false,
        }
      }
    }
    case albumsConst.UPLOAD_IMAGE_TO_ALBUMS_FAILED: {
      const {error} = action.payload;
      return {
        ...state,
        // listImageAlbums: {
        //   data: error.data,
        //   isLoading: false,
        //   error: error.data,
        // }
      }
    }
    case albumsConst.DELETE_IMAGE_ALBUMS: {
      return {
        ...state,
      }
    }
    case albumsConst.DELETE_IMAGE_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listImageAlbums: {
          data: state.listImageAlbums.data.filter(image => image.id !== data.data.id),
          isLoading: false,
          error: null,
        }
      }
    }
    case albumsConst.MOVE_IMAGE_TO_OTHER_ALBUM: {
      const {data} = action.payload;
      return {
        ...state,
       listImageAlbums: {
        data: state.listImageAlbums.data,
        isLoading: true,
        error: null,
       }
      }
    }


    case albumsConst.MOVE_IMAGE_TO_OTHER_ALBUM_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
       listImageAlbums: {
        data: state.listImageAlbums.data.filter(image => image.id !== data.imageId),
        isLoading: false,
        error: null,
       }
      }
    }
    case albumsConst.DELETE_USER_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listAlbums: {
          data: state.listAlbums.data.filter(album => album.id != data.data.id),
          isLoading: false,
          isLoadMore: false,
          error: null,
        }
      }
    }

    case albumsConst.DELETE_USER_ALBUMS_FAILED: {
      toastError('Không thể xóa albums mặc định');
      return {
        ...state,
      }
    }

    case albumsConst.EDIT_USER_ALBUMS_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listAlbums: {
          data: state.listAlbums.data.map((albums) => {
            if(albums.id == data.data.id){
              albums.name = data.data.name
            }
            return albums;
          }),
          isLoading: false,
          isLoadMore: false,
          error: null,
        }
      }
    }
    default:
      return state;
  }
}

export  default reducer;