import React from 'react';

const SelectOption = (
  { option, 
    onChangeOption 
  }) => {
  return (
    <select
      className="select-time-custom"
      onChange={(e) => onChangeOption(e.target.value)}
      value={option}
    >
      <option value="today">Hôm nay</option>
      <option value="this_month">Tháng này</option>
      <option value="this_quarter">Quý này</option>
      <option value="this_year">Năm này</option>
      <option value="option">Tùy chọn</option>
    </select>
  );
};

export default SelectOption;
