// VoiceForm.jsx
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { fetchVoice, resetVoice} from "actions/tts";

const validationSchema = Yup.object().shape({
  content: Yup.string()
    .required('Vui lòng nhập câu văn')
    .max(3000, 'Câu văn không quá 3000 ký tự'),
});

const VoiceForm = () => {
  const dispatch = useDispatch();
  const resetFormVoice = () => {
    dispatch(resetVoice());
  }

  return (
    <Formik
      initialValues={{ content: '' }}
      validationSchema={validationSchema}
      onSubmit={async(values, { setSubmitting}) => {
        try {
          await dispatch(fetchVoice(values));
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ resetForm}) => (
        <Form>
          <div className="mb-3">
            <label htmlFor="content">Câu văn</label>
            <Field as="textarea" name="content" rows={10} className="form-control" />
            <ErrorMessage name="content" component="label" className="alert-invalid " />
          </div>
          <div className="d-flex gap-1">
            <Button type="button" className='btn btn-secondary' onClick={() => {
              resetFormVoice();
              resetForm();
            }}>Xoá bỏ</Button>
            <Button type="submit" className='btn btn-warning'>Tạo giọng đọc</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VoiceForm;