import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ModalShare from "../../components/NewsFeed/Components/ModalShare";
import ModalSharePoint from "../../components/NewsFeed/Components/ModalSharePoint";
import {
  commentPostDetail,
  fetchSubCommentPost,
  modifyReplyData,
  replyCommentPostDetail,
  fetchPostDetailImage,
  fetchCommentPost,
  likePostDetail,
  likeCommentPostDetail,
  modifyEditData,
  editCommentPostDetail,
  deleteCommentPostDetail,
  deleteSubCommentPostDetail,
  editSubCommmentPostDetail,
  fastFollowUser,
  fastUnFollowUser,
} from "../../redux/actions/postDetailActions";
import { toastError } from "helpers/toastHelper";
import _ from "lodash";
import { toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

export const PostDetailContext = createContext({
  onClickMore: () => {},
  onClickReplyForm: () => {},
  onUserComment: () => {},
  onClickPost: () => {},
  onClickComment: () => {},
  isDisplayLoadmore: true,
  replyData: null,
});

const usePostDetail = () => {
  const postDetail = useSelector((state) => state.postDetail.post.postDisplay);
  const post = useSelector((state) => state.postDetail.post.data);
  return {
    id: postDetail.id,
    total_comment: post?.images?.length <= 1 ? post.total_comment : postDetail.total_comment,
    total_like: post?.images?.length <= 1 ? post.total_like : postDetail.total_like,
    viewed: post?.viewed,
    created_at: postDetail.created_at,
    user_liked: post?.user_liked,
    user_followed_shop: post?.user_followed_shop,
    shop_id: post?.shop_id,
    user: {
      id: postDetail.user_id,
      user_name: postDetail.user_name,
      user_image: postDetail.user_image,
      user_level: postDetail.user_level,
      shop_name: postDetail.shop_name,
      shop_image: postDetail.shop_image,
      user_liked: postDetail.user_liked,
      slug: post?.user_slug,
    },
    post_id: post?.id,
    content: {
      name: postDetail.name,
      images: _.sortBy(post?.images, (o) => o.id),
    },
    number_images: post?.images.length
  };
};
const PostDetailProvider = () => {

  const dispatch = useDispatch();
  const { postId, imageId } = useParams();
  const postDetail = usePostDetail();
  const navigate = useNavigate();
  const post = useSelector((state) => state.postDetail.post.data);
  const isLoading = useSelector((state) => state.postDetail.post.isLoading);
  const commentEntity = useSelector((state) => state.postDetail.comments.entity);
  const displayLoadmore = useSelector((state) => state.postDetail.comments.meta.displayLoadmore);
  const replyData = useSelector((state) => state.postDetail.comments.replyData);
  const editData = useSelector((state) => state.postDetail.comments.editData);
  const user = useSelector((state) => state.login.user_data.current_user);
  const currentUser = user;
  const isOwner = user?.id === postDetail?.user?.id;
  const [modal, setModal] = useState(null);
  const listComments = Object.values(commentEntity);
  
  const fileIndex = useMemo(() => {
    return postDetail?.content?.images?.findIndex((it) => it.id === postDetail.id);
  }, [postDetail]);

  const handleClickActions = (type) => {
    if (!currentUser) {
      toastErrorLogin(isMobile)
      return;
    }
    switch (type) {
      case "like":
        checkAuth();
        dispatch(
          likePostDetail({
            liked: post.user_liked,
            classable_id: post.classable_id,
            classable_type: post.classable_type,
          })
        );
        break;
      case "comment":
        break;
      case "share":
        toggleModal("share");
        break;
      case "share-point":
        toggleModal("share-point");
        break;

      default:
        break;
    }
  };

  const checkAuth = () => {
    if (!currentUser) {
      publish("showPopupLogin");
      throw new Error("unAuthorized");
    }
  };

  const handleUserInteractionComment = (type, { data, pattern }) => {
    switch (type) {
      case "like":
        if (!currentUser) {
          toastErrorLogin(isMobile)

          break;
        }
        dispatch(
          likeCommentPostDetail({
            pattern,
            liked: data.user_liked,
            classable_id: data.classable_id,
            classable_type: data.classable_type,
          })
        );
        break;

      case "comment":
        if (!currentUser) {
          toastErrorLogin(isMobile)

          break;
        }
        dispatch(
          replyCommentPostDetail({
            pattern,
            params: data,
          })
        );
        break;

      case "load-sub-comment":
        dispatch(
          fetchSubCommentPost({
            id: data.id,
            page: data.page,
          })
        );
        break;
      case "edit-comment":
        dispatch(
          editCommentPostDetail({
            pattern,
            params: data,
          })
        );
        break;
      case "edit-sub-comment": {
        dispatch(
          editSubCommmentPostDetail({
            pattern,
            params: data,
          })
        );
        break;
      }
      case "delete-comment":
        dispatch(
          deleteCommentPostDetail({
            pattern,
            params: data,
          })
        );
        break;
      case "delete-sub-comment":
        dispatch(
          deleteSubCommentPostDetail({
            pattern,
            params: data,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleFastFollowUser = (type) => {
    switch (type) {
      case "follow-user":
        dispatch(fastFollowUser({ shop_id: postDetail.shop_id }));
        break;
      case "un-follow-user":
        dispatch(fastUnFollowUser({ shop_id: postDetail.shop_id }));
        break;
      default:
        break;
    }
  };

  const handlUserComment = async (formValue) => {
    if (!currentUser) {
      toastErrorLogin(isMobile)

      return;
    }
    await dispatch(
      commentPostDetail({
        content: formValue.comment,
      })
    );
  };

  const handleVisibilityReplyForm = ({ id, replierId, parentId }) => {
    if (!currentUser) {
      toastErrorLogin(isMobile)

      return;
    }
    dispatch(
      modifyReplyData({
        parentId,
        replierId,
        id,
      })
    );
  };

  const handleVisibilityEditForm = ({ id, replierId, parentId }) => {
    if (!currentUser) {
      toastErrorLogin(isMobile)
      return;
    } else {
      dispatch(modifyEditData({ parentId, replierId, id }));
    }
  };

  const loadMoreComment = () => {
    dispatch(
      fetchCommentPost({
        classable_id: postId,
        classable_type: "Post",
      })
    );
  };

  const onNavigateFile = (type) => {
    const images = postDetail.content.images;
    let index = fileIndex;
    if (type === "next") {
      index++;
    } else if (type === "prev") {
      index--;
    }

    navigate(`/p/${postId}/i/${images[index].id}`, { replace: true });
  };

  const toggleModal = (type) => {
    setModal(type);
  };

  useEffect(() => {
    dispatch(fetchPostDetailImage({ postId, imageId }));
    return () => {
      dispatch({ type: "RESET_DATA" });
    };
  }, [postId, imageId]);

  const value = {
    loadMoreComment,
    onVisisibleReplyForm: handleVisibilityReplyForm,
    onVisisibleEditForm: handleVisibilityEditForm,
    onUserComment: handlUserComment,
    onClickActionsPost: handleClickActions,
    onClickActionsComment: handleUserInteractionComment,
    isDisplayLoadmore: displayLoadmore,
    replyData,
    editData,
    listComments,
    post: postDetail,
    onNavigateFile,
    showPrevButton: fileIndex > 0,
    showNextButton: fileIndex < postDetail?.content?.images?.length - 1,
    currentUser,
    isOwner,
    handleFastFollowUser: handleFastFollowUser,
  };
  return (
    <PostDetailContext.Provider value={value}>
      {isLoading ? (
        <Row className="h-100">
          <Col md={12} className="h-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </Col>
        </Row>
      ) : (
        <>
          <Outlet />
          <ModalShare isOpen={Boolean(modal === "share")} closeModal={() => setModal(null)} />
          <ModalSharePoint isOpen={Boolean(modal === "share-point")} closeModal={() => setModal(null)}  post={post}/>
        </>
      )}
    </PostDetailContext.Provider>
  );
};

export default PostDetailProvider;
