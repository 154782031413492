
import React, { useState, useEffect, useRef, useContext } from "react";
import "./PlantDiagnosis.scss";
import Webcam from "react-webcam";
import Slider from 'react-slick';
import AIScanIcon from '/assets/images/ai-scan.png';
import AIScanActiveIcon from '/assets/images/ai-scan-active.png';
import { trainingData } from 'apis/nutrition';
import { toastError, toastErrorDownloadAppDia } from "helpers/toastHelper";
import { useNavigate } from 'react-router-dom';
import AppContext from "components/app_context";
import { FaSyncAlt, FaPowerOff } from "react-icons/fa";
import { fetchDiagnosticsCategory } from "../../../apis/diagnostic";
import CancelIcon from '/assets/images/cancel (2).png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MAX_IMAGES = 5; // Số lượng hình ảnh tối đa

const PlantDiagnosis = () => {
  const [activeTab, setActiveTab] = useState("auto");
  const [crops, setCrops] = useState([]);
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const [isFrontFacing, setIsFrontFacing] = useState(true); // Trạng thái camera trước/sau
  const [images, setImages] = useState([]); // Danh sách hình ảnh
  const webcamRef = useRef(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const { isMobile } = useContext(AppContext);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchCrops = async () => {
      try {
        const response = await fetchDiagnosticsCategory();
        if (Array.isArray(response.data.data)) {
          setCrops(response.data.data);
        } else {
          setCrops([]);
        }
      } catch (err) {
        setError("Có lỗi khi lấy danh sách cây trồng. Vui lòng thử lại sau.");
      }
    };

    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
          },
          () => {
            setLat(10.8231); // Hồ Chí Minh
            setLng(106.6297);
          }
        );
      } else {
        setLat(10.8231);
        setLng(106.6297);
      }
    };

    fetchCrops();
    fetchLocation();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleImageUpload = async () => {
    if (images.length === 0) {
      setError("Vui lòng chọn ít nhất một hình ảnh.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append(`images[]`, image.file);
      });
      formData.append("lat", lat);
      formData.append("lng", lng);
      formData.append("category_id", activeTab === "auto" ? "" : activeTab);
      formData.append("nutrition_id", null);

      const response = await trainingData({ formData });
      if (response.status === 200) {
        navigate('ket-qua-chan-doan', { state: { result: response.data } });
      } else {
        return isMobile
          ? toastErrorDownloadAppDia(response.data)
          : toastError(response.data);
      }
    } catch (err) {
      setError("Không thể chẩn đoán, vui lòng thử lại.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ["image/jpeg", "image/png"];

    const newImages = files
      .filter(
        (file) =>
          validTypes.includes(file.type) &&
          !images.some((img) => img.file.name === file.name)
      )
      .map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

    if (images.length + newImages.length > MAX_IMAGES) {
      setError(`Chỉ tải tối đa ${MAX_IMAGES} hình ảnh.`);
      return;
    }

    setImages((prev) => [...prev, ...newImages]);
    setError(null);
  };

  const captureImage = async () => {
    if (images.length >= MAX_IMAGES) {
      setError(`Chỉ tải tối đa ${MAX_IMAGES} hình ảnh.`);
      return;
    }
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const newImage = {
        file: new File([blob], `webcam_${Date.now()}.jpg`, { type: "image/jpeg" }),
        preview: imageSrc,
      };

      setImages((prev) => [...prev, newImage]);
      setError(null);
    }
  };

  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    rows: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          rows: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
          rows: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          rows: 1,
        },
      },
    ],
  };

  const handleRemoveImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="plant-diagnosis-container">
    { crops ?
        <div className="row mb-5 justify-content-center">
          <div className="crop-slider mt-3">
            <Slider {...settings} ref={sliderRef}>
              <div
                className={`crop-item ${activeTab === 'auto' ? 'active' : ''}`}
              >
                <button
                  className="tab-button box-category"
                  onClick={() => handleTabChange('auto')}
                  style={{
                    backgroundImage: `url(${activeTab === 'auto' ? AIScanActiveIcon : AIScanIcon})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                </button>
                <p className="crop-name">Tự động</p>
              </div>

              {crops.map((crop) => (
                <div
                  key={crop.id}
                  className={`crop-item ${activeTab === crop.id ? 'active' : ''}`}
                >
                  <button
                    className="tab-button box-category"
                    onClick={() => handleTabChange(crop.id)}
                    style={{
                      backgroundImage: `url(${crop.icon})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                  </button>
                  <p className="crop-name">{crop.name}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      :
        <></>
    }
      <div className="training-content">
        <div className="image-upload-section">
          <div className="images-preview">
            {images.map((img, index) => (
              <div key={index} className="image-preview-item">
                <img src={img.preview} alt={`Hình ${index + 1}`} className="image"/>
                <button className="delete-image" onClick={() => handleRemoveImage(index)}>
                  <img src={CancelIcon} alt="Xóa"/>
                </button>
              </div>
            ))}
          </div>
          <p>
            Tải tối đa {MAX_IMAGES} hình ảnh.
            <br />
            <small>
              Cần hình ảnh cận cảnh và sắc nét để tăng độ chính xác (Cách điểm cần nhận dạng 20-30cm)
            </small>
          </p>
          {error && <p className="error-message text-danger">{error}</p>}
          { loading ? null :
            <>
              <div className="camera-app">
                {useWebcam && (
                  <div className="webcam-container">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      className="webcam-view"
                      videoConstraints={{
                        facingMode: isFrontFacing ? "user" : "environment", // Chuyển đổi giữa camera trước và sau
                      }}
                    />
                    {/* Icon đổi hướng camera */}
                    <button
                      className="toggle-camera-icon"
                      onClick={() => setIsFrontFacing(!isFrontFacing)}
                      title="Đổi hướng camera"
                    >
                      <FaSyncAlt />
                    </button>
                    {/* Nút chụp ảnh */}
                    <button className="capture-button" onClick={captureImage}>
                      <div className="inner-circle"></div>
                    </button>
                    {/* Nút tắt camera */}
                    <button className="power-button" onClick={() => setUseWebcam(false)}>
                      <FaPowerOff />
                    </button>
                  </div>
                )}
                {!useWebcam && (
                  <button className="start-camera-button" onClick={() => setUseWebcam(true)}>
                    Camera 📸
                  </button>
                )}
              </div>
              <div className="upload-container">
                <button
                  className="upload-button"
                  onClick={() => document.getElementById("file-input").click()}
                  disabled={loading}
                >
                  Tải ảnh 📤
                </button>
                <input
                  id="file-input"
                  type="file"
                  accept="image/jpeg,image/png"
                  className="file-input"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                />
              </div>
            </>
          }
          <button
            className="diagnose-button mt-2"
            onClick={handleImageUpload}
            disabled={loading}
          >
            {loading ? "Đang xử lý..." : "Thực hiện chẩn đoán"}
          </button>
        </div>

        {diagnosisResult && (
          <div className="diagnosis-result">
            <h4>Kết quả chẩn đoán:</h4>
            <p>{diagnosisResult.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlantDiagnosis;
