
const UseInvoice = () => {

  const invoiceStatus = {
    pending: {
      className: 'badge-warning',
      text: 'Chờ xác nhận',
    },
    cancelled: {
      className: 'badge-danger',
      text: 'Đã hủy'
    },
    delivered: {
      className: 'badge-success',
      text: 'Đã giao hàng'
    },
    failed_delivery: {
      className: 'badge-danger',
      text: 'Giao thất bại',
    },
    returned: {
      className: 'badge-danger',
      text: 'Trả hàng'
    },
    ready_to_ship: {
      className: 'badge-warning',
      text: 'Chờ lấy hàng'
    },
    shipped: {
      className: 'badge-warning',
      text: 'Đang giao',
    },
    rejected: {
      className: 'badge-danger',
      text: 'Người bán từ chối đơn'
    },
    admin_rejected: {
      className: 'badge-danger',
      text: 'Quản trị viên từ chối đơn',
    }
  }

  return {
    invoiceStatus,
  }
}

export default UseInvoice;
