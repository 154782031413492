import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbEnterprise = () => {
  const renderBreadcrumb = () => {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/cho-2nong`}>
              <span>Doanh nghiệp sản xuất</span>
            </Link>
          </li>
          <li className="breadcrumb-item active d-flex align-items-center">
            <span className="icon-arrow-black"></span>
            <span>CHỢ SẢN XUẤT</span>
          </li>
        </ol>
      </nav>
    );
  };

  return (
    <>
      {renderBreadcrumb()}
    </>
  );
};

export default BreadcrumbEnterprise;
