import React, { useEffect, useState } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { useNavigate, useParams } from "react-router-dom";
import {fetchDetailRefundInvoice, requestComplaintStatus, updateStatusRefundInvoice} from "actions/refund_invoice";
import { useDispatch, useSelector } from "react-redux";
import RefundStatus from "../../../context/Inovice/refundStatus";
import OrderInformation from "./Components/OrderInformation";
import InvoiceItems from "./Components/InvoiceItems";
import { Col, Row, Card } from 'react-bootstrap';
import Swal from "sweetalert2";
import './DetailRefundBusinessInvoice.scss'
import ShowMediaModal from "./Components/ShowMediaModal";
import RefundModal from "./Components/RefundModal";

const DetailRefundBusinessInvoice = ({type}) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const { refundStatus } = RefundStatus();
  const { data }  = useSelector(state => state.refundInvoices.detailRefundInvoice);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [returnModal, setReturnModal] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateStatusRefundInvoice = async (status) => {
    if(status === 'rejected'){
      setReturnModal(true)
    } else {
      Swal.fire({
        title: 'Thông báo',
        text: "Xác nhận chấp nhận yêu cầu trả hàng",
        icon: 'success',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#009a59',
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Huỷ'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateStatusRefundInvoice({ invoiceId: invoiceId, status: 'approved' }));
          navigate(`/lien-ket-danh-nghiep/thong-tin-don-hang-hoan-tra/${data.id}/business/${data.business_association_id}`);
          setTimeout(() => {
            dispatch(fetchDetailRefundInvoice({type: 'sales', invoiceId: invoiceId}));
          }, 1000);
        }
      });
    }
  }

  useEffect(() => {
    dispatch(fetchDetailRefundInvoice({type: 'sales', invoiceId: invoiceId}));
  }, [invoiceId]);

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <OrderInformation data={data} invoiceStatus={refundStatus} showReturnInfo={true} />
            <InvoiceItems sellerName={data?.business_association_name || data.seller_name} invoiceItems={data.invoice_items} totalPrice={ data.invoice_user_id !== undefined ? data.total_price : data.price_total} />
            { (data.status === 'rejected') ?
                <Card>
                  <Card.Header><p className="fw-bold">Lý do từ chối yêu cầu tử nguời bán</p></Card.Header>
                  <Card.Body>
                    <p>{ data?.reason }</p>
                    <Row>
                      {Array.isArray(data.seller_images) && data.seller_images.length > 0 && data.seller_images.some(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)) ? (
                        data.seller_images.filter(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)).slice(0, 3).map((media, index) => (
                          <Col key={index} xs={3} onClick={handleOpenModal}>
                            {index === 2 && data.seller_images.filter(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)).length > 3 ? (
                              <div className="image-overlay">
                                <img
                                  src={media.name}
                                  alt="img"
                                  title="photo"
                                  className="img-cover"
                                />
                                <div className="overlay">+{data.seller_images.length - 3}</div>
                              </div>
                            ) : (
                              <img
                                src={media.name}
                                alt="img"
                                title="photo"
                                className="img-cover"
                              />
                            )}
                          </Col>
                        ))
                      ) : (
                        ''
                      )}
                      {Array.isArray(data.seller_images) && data.seller_images.some(media => /\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)) && (
                        <Col xs={3} onClick={handleOpenModal}>
                          {data.seller_images
                            .filter(media => /\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name))
                            .map((media, index) => (
                              <video key={index} controls className="video-cover">
                              <source src={media.name} type={`video/${media.name.split('.').pop()}`} />
                              </video>
                            ))}
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                  <ShowMediaModal title='Hình ảnh từ người bán' show={showModal} handleClose={handleCloseModal} mediaList={data.seller_images || []} />
                </Card>
              :
              (data.status === 'requested') ?
                <Card>
                  <Card.Header><p className="fw-bold">Lý do trả hàng từ người mua</p></Card.Header>
                  <Card.Body>
                    <p>{ data?.request }</p>
                    <Row>
                      {Array.isArray(data.buyer_images) && data.buyer_images.length > 0 && data.buyer_images.some(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)) ? (
                        data.buyer_images.filter(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)).slice(0, 3).map((media, index) => (
                          <Col key={index} xs={3} onClick={handleOpenModal}>
                            {index === 2 && data.buyer_images.filter(media => !/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)).length > 3 ? (
                              <div className="image-overlay">
                                <img
                                  src={media.name}
                                  alt="img"
                                  title="photo"
                                  className="img-cover"
                                />
                                <div className="overlay">+{data.buyer_images.length - 3}</div>
                              </div>
                            ) : (
                              <img
                                src={media.name}
                                alt="img"
                                title="photo"
                                className="img-cover"
                              />
                            )}
                          </Col>
                        ))
                      ) : (
                        ''
                      )}
                      {Array.isArray(data.buyer_images) && data.buyer_images.some(media => /\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name)) && (
                        <Col xs={3} onClick={handleOpenModal}>
                          {data.buyer_images
                            .filter(media => /\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i.test(media.name))
                            .map((media, index) => (
                              <video key={index} controls className="video-cover">
                                <source src={media.name} type={`video/${media.name.split('.').pop()}`} />
                              </video>
                            ))}
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                  <ShowMediaModal title='Hình ảnh từ người mua' show={showModal} handleClose={handleCloseModal} mediaList={data.buyer_images || []} />
                </Card>
              :
              ''
            }
            {
              data.status === 'requested' && data.complaint_status === 'none' ?
                <>
                  <div className="mt-2 d-flex">
                    <button className="btn btn-warning mr-2" onClick={() => handleUpdateStatusRefundInvoice('rejected')}>Từ chối</button>
                    <button className="btn btn-success" onClick={() => handleUpdateStatusRefundInvoice('approved')}>Chấp nhận</button>
                  </div>
                  <RefundModal
                    modalTitle = "Lý do từ chối trả hàng"
                    invoiceUserId = {data.id}
                    businessAssociationId = {data.business_association_id}
                    type={"seller"}
                    openModal={returnModal}
                    setOpenModal={setReturnModal}
                  />
                </>
              :
              ''
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailRefundBusinessInvoice;
