import { Link } from "react-router-dom";
import { useNotification } from "hooks/useNotification";
import ReactTimeAgo from 'react-time-ago';

const NotificationItem = ({ notification, viewNoti, removeNoti }) => {
  const notificationLink = useNotification(notification);
  const {linkReturn}  = notificationLink;
  const truncateString = (content) => {
    if (!content) return "";
    if (content.length > 200) {
      return content.substring(0, 200) + '...';
    }
    return content;
  }

  const content = truncateString(notification?.content);

  return (
    <li className="align-items-center" onClick={() => viewNoti(notification.id)}>
      <div style={{ maxWidth: '205px' }}>
        <Link to={linkReturn}>
          <span className="fw-bold">{notification.title}</span>
          <p className="m-0" dangerouslySetInnerHTML={{ __html: content}}></p>
          <small>
            <ReactTimeAgo date={Date.parse(notification.created_at)} />
          </small>
        </Link>
      </div>
      <span className={`${notification.status} new-circle`} />
      <span className="icon-close" title="Xóa" onClick={() => removeNoti(notification.id)} />
    </li>
  );
};
export default NotificationItem;
