// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-action-business .title-txt {
  font-size: 1.05rem;
  border-top: 1px solid #e1e1e1;
  padding: 8px 15px 5px;
  background-color: #adb5bd;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/BusinessAssociation/BusinessAction/BusinessAction.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".list-action-business .title-txt{\n    font-size: 1.05rem;\n    border-top: 1px solid #e1e1e1;\n    padding: 8px 15px 5px;\n    background-color: #adb5bd;\n    border-radius: 2px;\n    text-transform: uppercase;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
