// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-enterprise {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 16px;
  gap: 4px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/EnterpriseDetail/wrapper/SectionWrapper.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AACF","sourcesContent":[".section-enterprise {\n  display: flex;\n  flex-direction: column;\n  background: white;\n  border-radius: 8px;\n  padding: 16px;\n  gap: 4px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
