import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.options = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const notify_login = isMobile =>
  toast.error(
    isMobile ? (
      <div
        style={{ textAlign: 'justify', fontWeight: 'bold', padding: '5px' }}
        id="login-with-two-nong"
        className="btn login-modal-button"
        data-toggle="modal"
        data-target="#loginModal"
      >
        Vui lòng đăng nhập để có trải nghiệm tốt hơn
      </div>
    ) : (
      <div
        style={{ textAlign: 'justify', fontWeight: 'bold', padding: '5px' }}
        id="login-with-two-nong"
        className="btn login-modal-button"
        data-toggle="modal"
        data-target="#loginModal"
      >
        Vui lòng đăng nhập để có trải nghiệm tốt hơn
      </div>
    ),
    { toastId: 2, type: toast.TYPE.ERROR },
  );

const redirectDownloadApp = () => {
  window.location.assign('https://cho2nong.page.link/social');
};

const notifyDownloadApp = () => {
  toast.error(
    <div
      onClick={() => redirectDownloadApp()}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>Lỗi!</span>
      {'  '}
      <span style={{ marginLeft: 5 }}>Vui lòng tải app để đăng bài</span>
    </div>,
    { toastId: 2, type: toast.TYPE.ERROR },
  );
};

const notifyDownloadAppDia = (message) => {
  toast.error(
    <div
      onClick={() => redirectDownloadApp()}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>{message}</span>
    </div>,
    { toastId: 2, type: toast.TYPE.ERROR },
  );
};


export const toastSuccess = mess => {
  if (mess) {
    toast.success(`${mess}!`);
  }
};

export const toastError = error => {
  if (error) {
    toast.error(`${error}!`, { toastId: 2, type: toast.TYPE.ERROR });
  }
};

export const toastPending = mess => {
  if (mess) {
    toast.warn(`${mess}!`);
  }
};

export const toastErrorLogin = isMobile => {
  notify_login(isMobile);
  <ToastContainer
    className="toaster-container"
    position="top-right"
    autoClose={111111100}
    hideProgressBar={true}
    newestOnTop={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />;
};

export const toastErrorDownloadApp = () => {
  notifyDownloadApp();
  <ToastContainer
    className="toaster-container"
    position="top-right"
    autoClose={90000000000000000000000}
    hideProgressBar={true}
    newestOnTop={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />;
};

export const toastErrorDownloadAppDia = (message) => {
  notifyDownloadAppDia(message);
  <ToastContainer
    className="toaster-container"
    position="top-right"
    autoClose={90000000000000000000000}
    hideProgressBar={true}
    newestOnTop={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />;
};
