// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-preview-attachment {
  width: 40px;
  height: 40px;
  top: 50%;
  text-align: center;
  background-color: #6b6969;
  border: 0;
  border-radius: 50%;
}
.button-preview-attachment:hover {
  background-color: #c0c0c0;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsFeed/Components/PreviewAttachment.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,QAAA;EACA,kBAAA;EACA,yBAAA;EACA,SAAA;EACA,kBAAA;AACF;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".button-preview-attachment {\n  width: 40px;\n  height: 40px;\n  top: 50%;\n  text-align: center;\n  background-color: #6b6969;\n  border: 0;\n  border-radius: 50%;\n  &:hover {\n    background-color: #c0c0c0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
