import { Outlet, Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { toastErrorLogin } from '../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

export default function PrivateRoutes() {
  const isAuth = useAuth();
  if (!isAuth) {
    toastErrorLogin(isMobile);
  }
  return isAuth ? <Outlet /> : <Navigate to={"/"} />;
}
