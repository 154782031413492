import React, { useEffect, useContext, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as postActions from 'actions/post';
import * as authActions from 'actions/post';
import LeftSidebar from 'components/left_sidebar';
import AppContext from 'components/app_context';
import Post from 'components/shared/post/index';
import AddPost from 'components/add_post';
import FadeIn from 'react-fade-in';
import PostsPlaceholder from 'components/placeholder/posts';
import AddMetaTags from 'components/add_meta_tags';
import { PAGE, PER_PAGE } from 'constants';
import * as managerActions from 'actions/manager';
import { managerDeleteComment, managerDeleteSubComment } from 'apis/manager';
import ModalSharePost from 'components/shared/ModalSharePost';
import { toastError, toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function Posts(props) {
  let { data, isLoading, isLoadMore, meta } = props.listPosts;
  const point = useSelector(state => state.login.pointOfUser.point);
  let { postActionsCreator, authActionsCreator } = props;
  let [page, setPage] = useState(PAGE);
  let [pageMore, setPageMore] = useState(1);
  let [postId, setPostId] = useState(null);
  const [content, setContent] = useState('');
  const [currentPost, setCurrentPost] = useState(null);
  const { keyword, setDataFilter, current_user, hashtag } = useContext(AppContext);
  let {
    fetchPost,
    fetchUserPost,
    addPost,
    likePost,
    unlikePost,
    fetchLoadMorePost,
    deletePost,
    createCommentPost,
    sharePost,
    fetchCommentPost,
    likeComment,
    unlikeComment,
    updateComment,
    updateAnswerComment,
    replyCommentPost,
    deleteCommentPost,
    modifyDataDelete,
  } = postActionsCreator;
  const dispatch = useDispatch();
  let { managerUpdateComment, managerUpdateAnswerComment } = props.managerActionsCreator;

  let { sharePoint } = authActionsCreator;
  let comments = props.listCommentPosts;

  // useEffect(() => {
  //   fetchUserPost();
  // }, [fetchUserPost]);

  const postFilter = value => {
    setDataFilter(value);
  };
  function actionSharePoint(access_token, receiver_id, points) {
    sharePoint({
      access_token: access_token,
      receiver_id: receiver_id,
      points: points,
    });
    //dispatch(updatePointOfUser(point - points));
  }

  const actionLike = (id, type, isLiked) => {
    if (isLiked) {
      unlikePost({ classable_id: id, classable_type: type });
    } else {
      likePost({ classable_id: id, classable_type: type });
    }
  };

  const addComment = (id, type, content) => {
    createCommentPost({
      commentable_id: id,
      commentable_type: type,
      content: content,
    });
  };

  const actionDelete = id => {
    deletePost({ id: id });
  };

  // const actionSharePost = (id, type, content) => {
  //   sharePost({
  //     classable_id: id,
  //     classable_type: type,
  //     share_type: "config_share",
  //     post_type: "public",
  //     description: content,
  //   });
  // };

  useEffect(() => {
    fetchUserPost({ keyword: keyword || hashtag, page: page, limit: PER_PAGE });
  }, [fetchUserPost, keyword, hashtag]);

  let actionFetchCommentPost = id => {
    fetchCommentPost({
      classable_id: id,
      classable_type: 'Post',
      sort: 'total_likes',
    });
  };

  const actionLikeComment = (id, type, isLiked) => {
    if (isLiked) {
      unlikeComment({ classable_id: id, classable_type: type });
    } else {
      likeComment({ classable_id: id, classable_type: type });
    }
  };

  const actionReplyComment = (id, content) => {
    replyCommentPost({ id: id, content: content, replier_id: current_user.id });
  };

  const actionUpdateComment = (type, id, content) => {
    if (type == 'Comment') {
      if (current_user?.manager_type == 'admin' || current_user.manager_type == 'smod') {
        managerUpdateComment({ id: id, content: content });
      } else {
        updateComment({ id: id, content: content });
      }
    } else {
      if (
        current_user?.manager_type == 'admin' ||
        current_user.manager_type == 'smod' ||
        current_user.manager_type == 'mod'
      ) {
        managerUpdateAnswerComment({ id: id, content: content });
      } else {
        updateAnswerComment({ id: id, content: content });
      }
    }
  };

  const deleteComment = (id, role, type_comment) => {
    if (type_comment === 'Comment') {
      if (role === 'admin' || role === 'smod') {
        managerDeleteComment(id).then(res => {});
      } else {
        deleteCommentPost({ id: id });
      }
    } else {
      managerDeleteSubComment(id).then(res => {});
    }
  };

  const handleSharePost = async () => {
    if (current_user) {
      await sharePost({
          classable_id: currentPost.id,
          classable_type: 'Post',
          share_type: 'config_share',
          post_type: 'public',
          description: content,
        })
      setCurrentPost(null);
    } else {
      toastErrorLogin(isMobile)
    }
  };

  const actionSortComment = (id, type) => {
    if (type === 'asc') {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'total_likes',
      });
    } else {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'created_at',
      });
    }
  };
  return (
    <div className="posts">
      <AddMetaTags title={'Danh sách bài viết'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <AddPost current_user={current_user} addPost={addPost} />
          {isLoading && (
            <FadeIn>
              <PostsPlaceholder />
            </FadeIn>
          )}
          <FadeIn>
            {data.map(function (post, index) {
              return (
                <div key={index}>
                  <Post
                    post={post}
                    postFilter={postFilter}
                    actionLike={actionLike}
                    isOwner={true}
                    actionDelete={actionDelete}
                    addComment={addComment}
                    current_user={current_user}
                    // actionSharePost={actionSharePost}
                    actionSharePoint={actionSharePoint}
                    actionFetchCommentPost={actionFetchCommentPost}
                    comments={comments.data}
                    setPageMore={setPageMore}
                    setPostId={setPostId}
                    postId={postId}
                    actionLikeComment={actionLikeComment}
                    actionReplyComment={actionReplyComment}
                    actionUpdateComment={actionUpdateComment}
                    deleteComment={deleteComment}
                    // handleSharePost={handleSharePost}
                    setCurrentPost={setCurrentPost}
                    actionSortComment={actionSortComment}
                    modifyDataDelete={modifyDataDelete}
                    meta={meta}
                  />
                </div>
              );
            })}
          </FadeIn>
          <ModalSharePost
            post={currentPost}
            handleClose={() => setCurrentPost(null)}
            setContent={setContent}
            onSubmit={handleSharePost}
            current_user={current_user}
            content={content}
          />
          {/* <Loader isLoading={isLoading} />
          {isLoadMore && (
            <div className="post-more" onClick={listener}>
              <span className="txt hover fw-500">
                <u>Xem thêm</u>
              </span>
              <span className="icon-arrow-solid"></span>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    listPosts: state.post.listPosts,
    listCommentPosts: state.post.listCommentsPost,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
