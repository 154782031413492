import NumberFormat from 'components/number_format';
import React, { useState } from 'react';
import FadeIn from 'react-fade-in';
import ModalShareConfirm from 'components/shared/modalShareConfirm/modal';

export const GiftForYou = ({
  giftForYou,
  setPointOfGift,
  userPoint,
  handleExchangePointsToGift,
  getNumberDay,
  checkDueDay,
  hiddenButtonExchangGift,
  checkGiftStatusComingSoon,
}) => {
  const [show, setShow] = useState('');
  return (
    <>
      <div className="mt-4">
        <h5 className="title-line-lt text-uppercase fw-bold mb-3">
          <span className="d-block">Quà tặng cho bạn</span>
        </h5>
        <FadeIn className="row prod-list" childClassName="col-sm-4 col-xs-6 col-md-6 col-lg-3 col-12p5">
          {giftForYou?.map(function (gift, index) {
            const imageGift = gift.image ?? '/images/product-image-default.png';
            const quantityExchanged = gift.quantity - gift.quantity_exchanged;
            let giftComingSoon = checkGiftStatusComingSoon(gift?.start_date, gift?.end_date);
            return (
              <div className="item-lst" key={index}>
                <div className="bg-img-orgnal" style={{ background: `url(${imageGift})` }} />
                {!giftComingSoon && checkDueDay(gift.end_date) <= 3 && (
                  <div className="flashit">
                    <p>Sắp hết hạn</p>
                  </div>
                )}
                {giftComingSoon && (
                  <div className="coming_soon">
                    <p>Sắp diễn ra</p>
                  </div>
                )}
                <div className="prod-inf">
                  <div className="txt-name">{gift.name}</div>
                  <div className="price-item">
                    <div className="price01">
                      <div className="fs-7">
                        Quà đã đổi:{' '}
                        <span className="fw-semibold">
                          {gift.quantity_exchanged}/{gift.quantity}
                        </span>
                      </div>
                    </div>
                    <div className="price02 d-flex">
                      <div className="fs-7">
                        Điểm:
                        <span className="fw-semibold red-clr">
                          <NumberFormat>{gift.points}</NumberFormat>
                        </span>
                      </div>
                      <div className="ms-auto">
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            setShow(gift.id), setPointOfGift(gift.points);
                          }}
                          disabled={
                            (userPoint < gift.points) | (quantityExchanged <= 0) ||
                            hiddenButtonExchangGift(gift.status) | giftComingSoon
                          }
                        >
                          Đổi quà
                        </button>
                      </div>
                      {show && (
                        <ModalShareConfirm
                          isOpen={show}
                          onToggle={() => setShow('')}
                          onShare={() => handleExchangePointsToGift(show, gift.id)}
                          option="Đổi quà"
                        />
                      )}
                    </div>
                    {!giftComingSoon ? (
                      <div className="gift-exchange-deadline">
                        <div className="fs-7">
                          Hạn: <span className="fw-semibold red-clr">{getNumberDay(gift.end_date)}</span>
                        </div>
                      </div>
                    ) : (
                      <div className={'fs-7'} style={{ height: '6%' }}></div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </FadeIn>
      </div>
    </>
  );
};
