import React from "react";
import Avatar from "components/Avatar";
import ReactTimeAgo from "react-time-ago";
import "./NewsFeedHeader.scss";
import FollowUser from 'components/shared/FollowUser/index';
import { Link } from "react-router-dom";

const NewsFeedHeader = ({
  avatarUrl,
  userName,
  userSlug,
  createdAt,
  isOwner,
  post,
  handleFastFollowUser
}) => {
  const renderFollowUser = () => {
    switch (isOwner) {
      case true: {
        return;
      }
      case false: {
        return post.user_followed_shop ? (
          <a className="follow pointer ml-2" onClick={() => handleFastFollowUser("un-follow-user")}>
            <div title="Đang theo dõi">
              <img src="/images/not-follow.png" style={{ width: "15px" }} />
            </div>
          </a>
        ) : (
          <a className="not-follow pointer ml-2" onClick={() => handleFastFollowUser("follow-user")}>
            <div title="Theo dõi">
              <img src="/images/follow.png" style={{ width: "15px" }} />
            </div>
          </a>
        );
      }
      default: {
      }
    }
  };

  return (
    <React.Fragment>
      <div className="NewFeedHeader">
        <div className="NewFeedUserInfo d-block wrap-flwing">
          <div className="user-name d-flex align-items-center"> 
            <Link to={`/nguoi-dung/${userSlug?.toString() === 'null' ? post?.user.id : userSlug}`} className="NewFeedUserName text-limit">
             {userName}
            </Link>
            {!isOwner && renderFollowUser()}
          </div> 
          <Link to={`/nguoi-dung/${userSlug?.toString() === 'null' ? post?.user.id : userSlug}`} className="d-flex">
            <Avatar {...(avatarUrl && { src: avatarUrl })} />
            <div className="ms-2">
              <ReactTimeAgo date={createdAt} className="text-muted" />
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsFeedHeader;
