import { useRef, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import Avatar from 'components/Avatar';
import './Comment.scss';
import NewFeedComments from './NewFeedComments';
import CommentInput from './CommentInput';
import ModalReportComment from './ModalReportComment';
import EditCommentInput from './EditCommentInput';
function Comment({
  comment,
  parent,
  visibilityFormReply,
  visibilityFormEdit,
  setVisibilityFormReply,
  handleUserInteractionComment,
  setVisibilityFormEdit,
  owner,
  currentUser,
}) {
  const inputRef = useRef(null);
  const parentId = parent ? parent.id : '';
  let [open, setOpen] = useState(false);
  let [commentID, setCommentID] = useState(null);
  let [commentType, setCommentType] = useState('');
  const handleShowCommentInput = () => {
    if (parent) {
      setVisibilityFormReply({
        id: parent.id,
        replierId: comment.user_id,
        parentId: comment.id,
      });
    } else {
      setVisibilityFormReply({
        id: comment.id,
        replierId: comment.user_id,
      });
    }
  };

  const handleShowEditCommentInput = () => {
    if (comment.classable_type === 'Comment') {
      setVisibilityFormEdit({
        id: comment.id,
        replierId: comment.user_id,
      });
    } else {
      setVisibilityFormEdit({
        id: comment.id,
        replierId: comment.user_id,
        parentId: comment.comment_id,
      });
    }
  };

  const handleUserSubmitComment = formValue => {
    if (comment.classable_type === 'Comment') {
      handleUserInteractionComment('comment', {
        pattern: `${parent ? `comment-${parent}.children.` : ''}comment-${comment.id}.children`,
        data: {
          content: formValue.comment,
        },
      });
    }
  };

  const handleUserSubmitEditComment = formValue => {
    if (comment.classable_type === 'Comment') {
      handleUserInteractionComment('edit-comment', {
        pattern: `${parent ? `comment-${parent}` : ''}comment-${comment.id}`,
        data: {
          content: formValue.comment,
          id: comment.id,
        },
      });
    } else {
      handleUserInteractionComment('edit-sub-comment', {
        pattern: `comment-${comment.comment_id}.children`,
        data: {
          content: formValue.comment,
          id: comment.id,
          replier_id: comment.user_id,
          parentId: comment.comment_id,
        },
      });
    }
  };

  const handleUserDeleteComment = id => {
    if (comment.classable_type === 'Comment') {
      handleUserInteractionComment('delete-comment', {
        pattern: `${parent ? `comment-${parent}.` : ''}comment-${comment.id}`,
        data: {
          id: id,
        },
      });
    } else {
      handleUserInteractionComment('delete-sub-comment', {
        pattern: `comment-${comment.comment_id}.children`,
        data: {
          id: id,
          parentId: comment.comment_id,
        },
      });
    }
  };

  const renderCommentContent = item => {
    if (item.parent_id && item.replier_id) {
      return (
        <pre>
          <span className="text-primary">@{item.replier.name}</span>
          &nbsp;
          {item.content}
        </pre>
      );
    } else {
      return <pre>{item.content}</pre>;
    }
  };

  const openModal = (id, type) => {
    setCommentID(id);
    setCommentType(type);
    setOpen(true);
  };

  return (
    <div className="Comment d-flex py-1 align-items-start">
      <div className="me-1 p-1 flex-shrink-0">
        <Avatar {...(comment?.user?.image && { src: comment.user.image })} alt={comment?.user?.name} />
      </div>
      <div className="flex-grow-1">
        <div className="d-flex align-items-center">
          <div>
            <div className="CommentContentBox ">
              <div className="fw-bolder">{comment?.user?.name}</div>
              <div>{renderCommentContent(comment)}</div>
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle as="div" className="OptionButton">
              <span className="icon-menu-dot"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="div"
                href="#/action-1"
                onClick={() => openModal(comment.classable_id, comment.classable_type)}
              >
                Vi phạm
              </Dropdown.Item>
              {owner && (
                <>
                  <Dropdown.Item as="div" href="#/action-2" onClick={handleShowEditCommentInput}>
                    Cập nhật
                  </Dropdown.Item>
                  <Dropdown.Item as="div" href="#/action-3" onClick={() => handleUserDeleteComment(comment.id)}>
                    Xóa
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ModalReportComment open={open} setOpen={setOpen} commentID={commentID} commentType={commentType} />
        <div className="CommentActions">
          <div className="d-flex">
            <div
              className={classNames('CommentActionsText', {
                active: comment.user_liked,
              })}
              onClick={() =>
                handleUserInteractionComment('like', {
                  pattern: `${parent ? `comment-${parentId}.children.` : ''}comment-${comment.id}.${
                    comment.user_liked ? 'user_disliked' : 'user_liked'
                  }`,
                  data: comment,
                })
              }
            >
              {comment.user_liked ? 'Đã thích' : 'Thích'}
              <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
            </div>
            <div className="CommentActionsText" onClick={handleShowCommentInput}>
              Trả lời
            </div>
            <ReactTimeAgo date={Date.parse(comment.created_at)} className="CommentActionsText text-muted" />
          </div>
          {comment.total_answers > 0 && (
            <div
              onClick={() =>
                handleUserInteractionComment('load-sub-comment', {
                  data: { id: comment.id, page: comment.extraData.page },
                })
              }
              className={classNames('CommentActionsText', {
                loading: comment?.extraData?.loading,
              })}
            >
              &#9758; {comment.total_answers} phản hồi
            </div>
          )}
        </div>
        <NewFeedComments
          parent={comment}
          comments={Object.values(comment.children)}
          visibilityFormReply={visibilityFormReply}
          setVisibilityFormReply={setVisibilityFormReply}
          handleUserInteractionComment={handleUserInteractionComment}
          currentUser={currentUser}
          setVisibilityFormEdit={setVisibilityFormEdit}
          visibilityFormEdit={visibilityFormEdit}
        />
        {visibilityFormReply === comment.id && comment.id !== visibilityFormEdit && (
          <CommentInput ref={inputRef} onSubmit={handleUserSubmitComment} />
        )}
        {visibilityFormEdit === comment.id && comment.id !== visibilityFormReply && (
          <EditCommentInput ref={inputRef} content={comment.content} onSubmit={handleUserSubmitEditComment} />
        )}
      </div>
    </div>
  );
}

export default Comment;
