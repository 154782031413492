import React, { useState, useCallback, useEffect, useRef } from 'react';
import Navigation from 'components/navigation';
import { useNavigate, useLocation } from 'react-router-dom';
import Main from 'routers/routers';
import DeepLink from 'components/deeplink';
import 'assets/css/main.scss';
import 'bootstrap/dist/js/bootstrap';
import 'sweetalert2/src/sweetalert2.scss';
import 'i18n';
import { AppProvider } from 'components/app_context';
import TimeAgo from 'javascript-time-ago';
import vi from 'javascript-time-ago/locale/vi.json';
import Notification from 'components/firebaseNotifications/notification';
import PopupLogin from './loginForm';
import CurrentURL from 'components/CurrentURL';
import Footer from 'components/footer';
import ScrollButton from './ScrollButton';
import { get_user, getSessionId } from 'lib';
import { DOMAIN } from 'constants';
import ReactGA from 'react-ga4';
import usePageTracking from '../hooks/usePageTracking';

const TRACKING_ID = "G-1MX57ZY7RS";
ReactGA.initialize(TRACKING_ID);

TimeAgo.addDefaultLocale(vi);

export default function App() {
  let [value, setValue] = useState('');
  let [keyword, setKeyword] = useState('');
  let [user, setUser] = useState(null);
  let [updatedPhone, setUpdatedPhone] = useState(null);
  let navigate = useNavigate();
  const [dataUser, setDataUser] = useState(null);
  let [isMobile, setIsMobile] = useState(true);
  let [isLoadingNav, setIsLoadingNav] = useState(false);
  const [postIdForScroll, setPostIdForScroll] = useState(null);

  const isPageLoaded = useRef(false);
  const location = useLocation(); // Get the current location using useLocation hook
  usePageTracking();

  const handleResize = async () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Handle resize event
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setDataFilter = useCallback(value => {
    setValue(value);
  });

  const setSearch = useCallback(value => {
    setKeyword(value);
  });

  const initData = {
    hashtag: value,
    setDataFilter: setDataFilter,
    current_user: user,
    setUser: setUser,
    setUpdatedPhone: setUpdatedPhone,
    updatedPhone: updatedPhone,
    navigate: navigate,
    setSearch: setSearch,
    keyword: keyword,
    isMobile: isMobile,
    isLoadingNav: isLoadingNav,
    setIsLoadingNav: setIsLoadingNav,
    dataUser: dataUser,
    setDataUser: setDataUser,
    setPostIdForScroll: setPostIdForScroll,
    postIdForScroll: postIdForScroll,
    initializeChatbot: initializeChatbot,
  };

  function setupSlots(sessionId, name, accessToken) {
    if (!accessToken) {
      return;
    }

    const xhr = new XMLHttpRequest();
    var url = `https://core.chatbot.hainong.vn/conversations/${sessionId}/tracker/events`;
    var data = [
      {
        event: 'slot',
        name: 'user_token',
        value: accessToken,
      },
      {
        event: 'slot',
        name: 'user_name',
        value: name,
      },
    ];
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    const jsonData = JSON.stringify(data);
    xhr.onload = function () {
      if (xhr.status === 200) {
        console.info('Connect Success to chatbot');
      } else {
        console.info('False');
        console.error('Error:', xhr.statusText);
      }
    };

    xhr.send(jsonData);
  }

  function initializeChatbot(isMobile, sessionId = null, name = null, accessToken = null) {
    let listChatbotImg = 'https://chatbot.hainong.vn/chatbot-v2.gif';

    window.WebChat.default({
      customData: { language: 'vi' },
      socketUrl: 'https://core.chatbot.hainong.vn',
      initPayload: '/start_conversation',
      title: 'Trợ lý Hai Nông',
      inputTextFieldHint: 'Nội dung',
      mainColor: '#009a59',
      userBackgroundColor: '#009a59',
      closeImage: isMobile ? '/images/close-img.png' : listChatbotImg,
      openLauncherImage: listChatbotImg,
      speechToTextUrl: `${DOMAIN}/api/v2/speech_to_texts`,
      textToSpeechUrl: `${DOMAIN}/api/v2/text_to_speeds`,
      sessionConfirmCallback: setupSlots(sessionId, name, accessToken),
      autoClearCacheTimeout: 3 * 60 * 1000,
      speechToTextRecordTimeout: 30000,
      speechToTextVoiceAutoStopTimeout: 3 * 1000,
      messagesOnMicroErrors: {
        onConvertSTT: 'Có lỗi khi chuyển giọng nói thành tin nhắn. Bạn vui lòng thử lại!',
        onStartRecord: 'Vui lòng cấp quyền ghi âm!',
        onConvertMp3: 'Có lỗi khi ghi âm, vui lòng thử lại!',
      },
    });
  }

  useEffect(() => {
    if (isPageLoaded.current) return;
    isPageLoaded.current = true;
  }, []);

  useEffect(() => {
    if (isPageLoaded.current === true && user) {
      const { name, token2_user } = get_user();
      const session_id = getSessionId();
      initializeChatbot(isMobile, session_id, name, token2_user);
      const timeoutId = setTimeout(() => {
        const refreshedSessionId = getSessionId();
        console.log('refreshed session_id:', refreshedSessionId);
        initializeChatbot(isMobile, refreshedSessionId, name, token2_user);
      }, 1000);

      return () => clearTimeout(timeoutId);
    } else {
      initializeChatbot(isMobile);
    }
  }, [isMobile, user]);

  useEffect(() => {
    // Select the body element
    const body = document.body;

    // Create a new MutationObserver instance
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (body.classList.contains('id-portal-login-popup') && !body.classList.contains('modal-open')) {
            body.classList.remove('id-portal-login-popup');
          }
        }
      });
    });

    observer.observe(body, { attributes: true, attributeFilter: ['class'] });

    // Clean up
    return () => {
      observer.disconnect();
    };
  }, []);

  const hideNavFooter = location.pathname.includes('/modules/nhan-dang-sau-benh-oa');

  return (
    <AppProvider value={initData}>
      <div className="App">
        <DeepLink />
        {!hideNavFooter && <Navigation />}
        <div id="page-wrapper" className={!hideNavFooter ? '' : 'page-wrapper-custom'}>
          <div className="container">
            <CurrentURL>
              <Main />
            </CurrentURL>
          </div>
          <ScrollButton />
          {!hideNavFooter && <Footer />}
        </div>
        <Notification />
        <PopupLogin />
      </div>
    </AppProvider>
  );
}
