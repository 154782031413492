// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button-modal-prevew-file {
  position: absolute;
  top: 20px;
  left: 20px;
  filter: brightness(0) invert(1);
  opacity: 1 !important;
  padding: 10px;
  border-radius: 50%;
  z-index: 2;
}

.ModalViewContainer {
  overflow: scroll;
}
.ModalViewContainer .col-md-12 {
  height: 50%;
}
.ModalViewContainer .col-md-12 img {
  object-fit: contain;
  height: 100%;
}
.ModalViewContainer .CommentWrapper {
  flex-grow: 1;
  overflow-y: unset;
}

@media (min-width: 992px) {
  .ModalViewContainer {
    overflow: hidden;
  }
  .ModalViewContainer .col-md-12 {
    height: 100%;
  }
  .ModalViewContainer .col-md-12 img {
    object-fit: cover;
    height: 100%;
  }
  .ModalViewContainer .CommentWrapper {
    flex-grow: 1;
    overflow-y: auto;
  }
}
.threeDotsVertical {
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/ModalViewImagePage/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,+BAAA;EACA,qBAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,gBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AADI;EACE,mBAAA;EACA,YAAA;AAGN;AAAE;EACE,YAAA;EACA,iBAAA;AAEJ;;AACA;EACE;IACE,gBAAA;EAEF;EAAE;IACE,YAAA;EAEJ;EADI;IACE,iBAAA;IACA,YAAA;EAGN;EAAE;IACE,YAAA;IACA,gBAAA;EAEJ;AACF;AAEA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;AAAF","sourcesContent":[".close-button-modal-prevew-file {\n  position: absolute;\n  top: 20px;\n  left: 20px;\n  filter: brightness(0) invert(1);\n  opacity: 1 !important;\n  padding: 10px;\n  border-radius: 50%;\n  z-index: 2;\n}\n\n.ModalViewContainer {\n  overflow: scroll;\n  .col-md-12 {\n    height: 50%;\n    & img {\n      object-fit: contain;\n      height: 100%;\n    }\n  }\n  .CommentWrapper {\n    flex-grow: 1;\n    overflow-y: unset;\n  }\n}\n@media (min-width: 992px) {\n  .ModalViewContainer {\n    overflow: hidden;\n\n    .col-md-12 {\n      height: 100%;\n      & img {\n        object-fit: cover;\n        height: 100%;\n      }\n    }\n    .CommentWrapper {\n      flex-grow: 1;\n      overflow-y: auto;\n    }\n  }\n}\n\n.threeDotsVertical {\n  position: absolute;\n  z-index: 1;\n  top: 1.5rem;\n  right: 2rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
