import React, { useState, useEffect } from "react";
import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
  }),
};

const SelectProvice = (props) => {
  const { listProvinces, setProvinceId, isReset, setReset } = props;
  const [provinceLabel, setProvinceLabel] = useState("");
  const handleSelectChange = (selected) => {
    setReset(false);
    setProvinceLabel(selected); // Note!!!!: value(provinceLabel) must be mapping to object in list provices
    setProvinceId(selected?.value || null);
  };
  const resetSelect = () => {
    setProvinceLabel("");
  };
  useEffect(() => {
    if (isReset) {
      resetSelect();
    }
  }, [isReset]);

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Chọn tỉnh thành"
        hideSelectedOptions={true}
        noOptionsMessage={() => "Không tìm thấy"}
        isClearable={true}
        styles={customStyles}
        options={listProvinces}
        value={provinceLabel}
        onChange={handleSelectChange}
      />
    </>
  );
};
export default SelectProvice;
