// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-overlay {
  position: relative;
}
.image-overlay .img-cover {
  display: block;
}
.image-overlay .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.img-cover {
  width: 100%;
  height: 300px;
}

.video-cover {
  width: 100%;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/BusinessAssociation/BusinessInvoiceDetail/DetailRefundBusinessInvoice.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,cAAA;AAEJ;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACE,WAAA;EACA,aAAA;AACF;;AACA;EACE,WAAA;EACA,aAAA;AAEF","sourcesContent":[".image-overlay {\n  position: relative;\n  .img-cover {\n    display: block;\n  }\n\n  .overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.6);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    font-size: 1.5rem;\n    font-weight: bold;\n  }\n}\n.img-cover {\n  width: 100%;\n  height: 300px;\n}\n.video-cover {\n  width: 100%;\n  height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
