import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import NumberFormat from 'components/number_format';
import moment from 'moment/moment';
import ComplaintStatus from "../../../context/Inovice/complaintStatus";

const OrderInformation = ({ data, invoiceStatus, showReturnInfo }) => {
  const { complaintStatus } = ComplaintStatus();
  return (
    <section>
      <Card>
        <Card.Header><p className="fw-bold">Thông tin đơn hàng</p></Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>Mã đơn hàng: </strong>
                <span>{data?.sku || data?.invoice_user_sku}</span>
              </p>
              <p>
                <strong>Người nhận: </strong>
                <span>{data?.name}</span>
              </p>
              <p>
                <strong>Số điện thoại: </strong>
                <span>{data?.phone_number}</span>
              </p>
              <p>
                <strong>Email: </strong>
                <span>{data?.email}</span>
              </p>
              <p>
                <strong>Thời gian đặt: </strong>
                <span>{moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
              </p>
              <p>
                <strong>Phường: </strong>
                <span>{data?.ward_name}</span>
              </p>
              <p>
                <strong>Quận/Huyện: </strong>
                <span>{data?.district_name}</span>
              </p>
              <p>
                <strong>Tỉnh/Thành phố: </strong>
                <span>{data?.province_name}</span>
              </p>
            </div>
            <div className="col-md-6" >
              {data.status !== "pending" && (
                <>
                  <p>
                    <strong>{data.invoice_user_id !== undefined ? 'Mã hoàn vận đơn:' : 'Mã vận đơn:'} </strong>
                    <span>{data?.tracking_code}</span>
                  </p>
                  <p>
                    <strong>Đơn vị vận chuyển: </strong>
                    <span>{data?.shipment_provider}</span>
                  </p>
                </>
              )}
              <p>
                <strong>Người bán: </strong>
                <span>{data?.business_association_name || data?.seller_name}</span>
              </p>
              <p>
                <strong>Hình thức giao dịch: </strong>
                <span>Tiền mặt</span>
              </p>
              <p>
                <strong>Tổng tiền: </strong>
                <span><NumberFormat>{(data?.invoice_user_id !== undefined) ? data?.total_price : data?.price_total}</NumberFormat> đ</span>
              </p>
              <p>
                <strong>Số lượng mặt hàng: </strong>
                <span>{data?.quantity}</span>
              </p>
              <p>
                <strong>Trạng thái: </strong>
                {
                  data.invoice_user_id !== undefined ?
                    <span className={'badge badge-pill badge-warning'}>
                      Trả hàng
                    </span>
                  :
                  <span className={`badge badge-pill ${invoiceStatus[(data?.status)]?.className}`}>
                    {invoiceStatus[(data?.status)]?.text}
                  </span>
                }
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <strong>{showReturnInfo ? 'Địa chỉ lấy hàng:' : 'Địa chỉ nhận hàng:'} </strong>
                <span>{data?.shipping_address || data?.address}</span>
              </p>
            </div>
          </div>
          <hr />
          {showReturnInfo ?
            <div className="row">
              <div className="col-md-6">
                <p>
                  <strong>Mã yêu cầu trả hàng: </strong>
                  <span>{data?.refund_invoice_number}</span>
                </p>
                <p>
                  <strong>Thời gian trả: </strong>
                  <span>{moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
                </p>
                <p>
                  <strong>Đơn vị vận chuyển: </strong>
                  <span>{data?.shipment_provider}</span>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Trạng thái trả hàng: </strong>
                  <span className={`badge badge-pill ${invoiceStatus[(data?.status)]?.bgColor}`}>
                    {invoiceStatus[(data?.status)]?.text}
                  </span>
                </p>
                <p>
                  <strong>Trạng thái khiếu nại: </strong>
                  <span className={`badge badge-pill ${complaintStatus[(data?.complaint_status)]?.className}`}>
                    {complaintStatus[(data?.complaint_status)]?.text}
                  </span>
                </p>
              </div>
            </div>
          :
            ''
          }
        </Card.Body>
      </Card>
    </section>
  );
};

export default OrderInformation;
