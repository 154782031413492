import * as bannerConst from 'constants/banner';

const initialState = {
  banners: [],
  order: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case bannerConst.FETCH_BANNER_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        banners: data,
      };
    }
    case bannerConst.SAVE_BANNER_ORDER_SUCCESS: {
      const params = action.payload.data;
      return {
        ...state,
        order: [...state.order, params],
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
