import React from 'react';
import LeftSidebar from 'components/left_sidebar';
import AddMetaTags from 'components/add_meta_tags';
import PlantDiagnosis from './Components/PlantDiagnosis';

const TrainingData = () => {
  return (
    <>
      <AddMetaTags title={"Nhận dạng sâu bệnh"} />
      <div className="tts">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <h2 className='text-center'>Nhận dạng sâu bệnh</h2>
            <div className="cont-center saved-items">
              <div className='row'>
                <div className='col-sm-12'>
                  <PlantDiagnosis />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingData;
