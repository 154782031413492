import React, { useState, useEffect } from "react";
import { getReportRevenue } from "../../apis/report_revenue";
import SelectOption from "./components/selectOption";
import SelectField from "./components/selectField";
import { get_user } from "../../lib";
import AddMetaTags from "../../components/add_meta_tags";
import { Card } from "react-bootstrap";
import Left_sidebar from "../../components/left_sidebar";
import Revenue from "./components/revenue";


const ReportRevenueIndex = () => {
  const [reportData, setReportData] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date());
  const [dateOption, setDateOption] = useState('today')
  const [fetching, setFetching] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const user = get_user();

  function getDateRange(dateOption) {
    const currentDate = new Date();
    setDateOption(dateOption)

    switch (dateOption) {
      case 'today':
        return {
          startDate: currentDate,
          endDate: currentDate,
        };
      case 'this_month':
        return {
          startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
          endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
        };
      case 'this_quarter':
        const quarter = Math.floor(currentDate.getMonth() / 3);
        const quarterStartMonth = quarter * 3;
        return {
          startDate: new Date(currentDate.getFullYear(), quarterStartMonth, 1),
          endDate: new Date(currentDate.getFullYear(), quarterStartMonth + 3, 0),
        };
      case 'this_year':
        return {
          startDate: new Date(currentDate.getFullYear(), 0, 1),
          endDate: new Date(currentDate.getFullYear(), 11, 31),
        };
      default:
        return {
          startDate: new Date(),
          endDate: new Date(),
        };
    }
  }

  const handleCustomDate = async (value, type) => {
    if (type === 'to') setEndDate(value)
    else setStartDate(value)

  }

  const handleOptionChange = (option) => {
    const { startDate, endDate } = getDateRange(option);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFetchReport = async () => {
    setFetching(true)
    if (user) {
      const params = {
        start_date: String(startDate),
        end_date: String(endDate)
      }
      const { data } = await getReportRevenue(params)
      setFetching(false)
      setReportData(data)
      setIsShow(true)
    }
  }

  return (
    <>
      <div>
        <AddMetaTags title={"Doanh thu bán hàng"} />
        <div className="row">
          <Left_sidebar />
          <div className="col-md-9 col-sm-12">
            <div className="cont-center saved-items">
              <Card className="p-0 mt-3">
                <Card.Body className="custom-card-body">
                <h4>Doanh thu bán hàng</h4>
                  <div className="mt-3">
                    <div>Thời gian báo cáo</div>
                    <div className="row mt-2 justify-content-center">
                      <div className="col-sm-3">
                        <SelectOption
                          option={dateOption}
                          onChangeOption={handleOptionChange} />
                      </div>
                      <SelectField
                        type='from'
                        parentClass="col-sm-3 text-center"
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleCustomDate} />
                      <SelectField
                        type='to'
                        parentClass="col-sm-3 text-center"
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleCustomDate} />
                      <div className="text-left col-sm-3">
                        <button className="btn button-view-revenue" onClick={() => handleFetchReport()}>Xem</button>
                      </div>
                    </div>
                    {isShow && (
                      <>
                        <Revenue
                          reportData={reportData.data}
                          startDate={startDate}
                          endDate={endDate}
                          user={user} 
                        />
                      </>
                    )} 
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportRevenueIndex;
