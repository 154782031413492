import useManageContribute from "../Hooks/useManageContribute";
import moment from "moment";
import ModalViewImage from "./ModalViewImage";
const Pets = () => {
  const {
    contributePets,
    handleApproveContributePests,
    handleDeleteContributePests,
    expandMore,
    numItems,
    showModal,
    openModal,
    closeModal,
    imageUrl,
  } = useManageContribute();
  const { data } = contributePets;

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="align-middle text-s">
              Loại cây
            </th>
            <th scope="col" className="align-middle text-s">
              Bệnh
            </th>
            <th scope="col" className="align-middle text-s">
              Ảnh
            </th>
            <th scope="col" className="align-middle text-s">
              Khu vực
            </th>
            <th scope="col" className="align-middle text-s">
              Ngày đóng góp
            </th>
            <th scope="col" className="align-middle text-s">
              Người đăng
            </th>
            <th scope="col" className="align-middle text-s">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.slice(0, numItems).map(function (data) {
              return (
                <tr className="text-capitalize fs-14" key={data.id}>
                  <td className="align-middle">{data.tree_name}</td>
                  <td className="align-middle">{data.pest_name}</td>
                  <td className="align-middle min-w-100">
                    <img
                      className="image-contributing-cursor"
                      src={data.image}
                      alt="Image"
                      style={{ width: "100px", height: "100px" }}
                      onClick={() => showModal(data.image)}
                    />
                  </td>
                    <ModalViewImage openModal={openModal} closeModal={closeModal} image={imageUrl} />
                  <td className="align-middle">
                    {data.district_name}, {data.province_name}
                  </td>
                  <td className="align-middle">
                    {moment(data.created_at).format("DD-MM-YYYY hh:mm")}
                  </td>
                  <td className="align-middle">{data.user_name}</td>
                  <td className="align-middle table_actions">
                    <button
                      className="btn btn-danger btn-sm mr-2"
                      onClick={() => handleDeleteContributePests(data.id)}
                    >
                      Từ chối
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleApproveContributePests(data.id)}
                    >
                      Duyệt
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="font-italic font-light text-capitalize mt-3">
              Không có đóng góp
            </div>
          )}
        </tbody>
      </table>
      {data.length > 10 && numItems < data.length && (
        <div
          className="post-more background-clr-f5  mt-3 w-100  hover"
          onClick={() => expandMore()}
        >
          <span className="txt">
            <u
              style={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
              }}
            >
              Xem thêm
            </u>
          </span>
          <span className="icon-arrow-solid"></span>
        </div>
      )}

    </>
  );
};

export default Pets;
