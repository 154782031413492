import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as postActions from 'actions/post';
import * as authActions from 'actions/auth';
import * as managerActions from 'actions/manager';
import Post from './components/post';
import AppContext from 'components/app_context';
import LeftSidebar from 'components/left_sidebar';
import RightSidebar from 'components/right_sidebar';
import AddPost from 'components/add_post';
import AddMetaTags from 'components/add_meta_tags';
import { managerDeleteSubComment } from 'apis/manager';
import { fetchFollowing, followUser, unFollow, fetchPointOfUser, updatePointOfUser } from 'actions/auth';
import ScrollToTop from 'components/ScrollToTop';
import ModalSharePost from '../../components/shared/ModalSharePost';
import { toastError, toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';
function PostDetail(props) {
  let { postId: Id } = useParams();

  const [currentPost, setCurrentPost] = useState(null);
  let { postActionsCreator, authActionsCreator, managerActionsCreator } = props;
  let {
    likePost,
    unlikePost,
    createCommentPost,
    deleteCommentPost,
    sharePost,
    reportPost,
    fetchCommentPost,
    replyCommentPost,
    reportComment,
    likeComment,
    unlikeComment,
    updateComment,
    updateAnswerComment,
    fetchPostDetail,
    likePostDetailPage,
    unlikePostDetailPage,
  } = postActionsCreator;

  let [pageMore, setPageMore] = useState(1);
  let [postId, setPostId] = useState(null);
  let { sharePoint } = authActionsCreator;

  let { data: dataUpdateComment } = props.updateComment;
  let { data: dataUpdateAnswerComment } = props.managerUpdateAnswerComment;
  let comments = props.listCommentPosts;
  let { managerUpdateComment, managerUpdateAnswerComment, managerDeleteCommentPostDetail } = managerActionsCreator;
  let { data: post, error } = props.postDetail;
  const { current_user, navigate, setDataFilter } = useContext(AppContext);
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  const { data: listFollow } = useSelector(state => state.login.list_following);
  const point = useSelector(state => state.login.pointOfUser.point);

  useEffect(() => {
    fetchPostDetail(Id);
    if (Id) {
      actionFetchCommentPost(Id);
    }
  }, [fetchPostDetail, Id]);

  if (error) {
    navigate('/404');
  }
  useEffect(() => {
    if (current_user) {
      dispatch(fetchFollowing({ page: '', limit: '' }));
      dispatch(fetchPointOfUser(current_user.id));
    }
  }, [current_user]);
  let isOwner = current_user ? post?.user_id === current_user.id : false;

  const actionLike = (id, type, isLiked) => {
    if (isLiked) {
      unlikePostDetailPage({ classable_id: id, classable_type: type });
    } else {
      likePostDetailPage({ classable_id: id, classable_type: type });
    }
  };

  const addComment = (id, type, content) => {
    createCommentPost({
      commentable_id: id,
      commentable_type: type,
      content: content,
    });
  };
  let actionFetchCommentPost = id => {
    fetchCommentPost({
      classable_id: id,
      classable_type: 'Post',
      page: 1,
      sort: 'total_likes',
    });
  };

  const actionSharePost = () => {
    if (current_user) {
      sharePost({
        classable_id: currentPost.id,
        classable_type: 'Post',
        share_type: 'config_share',
        post_type: 'public',
        description: content,
      });

      setCurrentPost(null);
    } else {
      toastErrorLogin(isMobile);
    }
  };

  function actionSharePoint(access_token, receiver_id, points) {
    sharePoint({
      access_token: access_token,
      receiver_id: receiver_id,
      points: points,
    });
    //dispatch(updatePointOfUser(point - points));
  }

  const actionReportPost = (id, reason) => {
    reportPost({ id: id, reason: reason });
  };

  const actionReportComment = (user_id, reason, classable_id, type) => {
    reportComment({
      id: user_id,
      reason: reason,
      classable_id: classable_id,
      classable_type: type,
    });
  };

  const actionReplyComment = (id, content) => {
    replyCommentPost({ id: id, content: content, replier_id: current_user.id });
  };

  const actionUpdateComment = (type, id, content) => {
    if (type == 'Comment') {
      if (current_user?.manager_type == 'admin' || current_user.manager_type == 'smod') {
        managerUpdateComment({ id: id, content: content });
      } else {
        updateComment({ id: id, content: content });
      }
    } else {
      if (
        current_user?.manager_type == 'admin' ||
        current_user.manager_type == 'smod' ||
        current_user.manager_type == 'mod'
      ) {
        managerUpdateAnswerComment({ id: id, content: content });
      } else {
        updateAnswerComment({ id: id, content: content });
      }
    }
  };

  const actionSortComment = (id, type) => {
    if (type === 'asc') {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        sort: 'total_likes',
      });
    } else {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        sort: 'created_at',
      });
    }
  };

  const actionLikeComment = (id, type, isLiked) => {
    if (isLiked) {
      unlikeComment({ classable_id: id, classable_type: type });
    } else {
      likeComment({ classable_id: id, classable_type: type });
    }
  };

  const deleteComment = (id, role, type_comment) => {
    if (type_comment === 'Comment') {
      if (role === 'admin' || role === 'smod') {
        managerDeleteCommentPostDetail(id);
      } else {
        deleteCommentPost({ id: id });
      }
    } else {
      managerDeleteSubComment(id).then(res => {});
    }
  };
  const handleFollowUser = async shop_id => {
    await dispatch(
      followUser({
        classable_id: shop_id,
        classable_type: 'Shop',
        access_token: current_user?.token_user,
      }),
    );
    dispatch(fetchFollowing({ page: '', limit: '' }));
  };

  const handleUnFollowUser = async shop_id => {
    dispatch(
      unFollow({
        classable_type: 'Shop',
        classable_id: shop_id,
        access_token: current_user?.token_user,
      }),
    );
  };

  const postFilter = value => {
    setDataFilter(value);
    navigate("/")
  };


  return (
    <ScrollToTop>
      <div className="post-detail">
        <AddMetaTags />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12">
            <AddPost current_user={current_user} />
            {post && (
              <Post
                postIdDetail={Id}
                postFilter={postFilter}
                post={post}
                isOwner={isOwner}
                isLoadMore={comments.isLoadMore}
                comments={comments.data}
                current_user={current_user}
                actionLike={actionLike}
                addComment={addComment}
                actionFetchCommentPost={actionFetchCommentPost}
                setPageMore={setPageMore}
                setPostId={setPostId}
                postId={postId}
                actionSharePost={actionSharePost}
                actionSharePoint={actionSharePoint}
                actionReportPost={actionReportPost}
                actionReportComment={actionReportComment}
                actionLikeComment={actionLikeComment}
                actionSortComment={actionSortComment}
                actionReplyComment={actionReplyComment}
                actionUpdateComment={actionUpdateComment}
                deleteComment={deleteComment}
                dataUpdateComment={dataUpdateComment}
                dataUpdateAnswerComment={dataUpdateAnswerComment}
                setCurrentPost={setCurrentPost}
                handleFollowUser={handleFollowUser}
                handleUnFollowUser={handleUnFollowUser}
                listFollow={listFollow}
                point={point}
              />
            )}
          </div>
          <ModalSharePost
            post={currentPost}
            handleClose={() => setCurrentPost(null)}
            setContent={setContent}
            onSubmit={actionSharePost}
            current_user={current_user}
            content={content}
          />
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}
const mapStateToProps = state => {
  return {
    listPosts: state.post.listPosts,
    listCommentPosts: state.post.listCommentsPost,
    listCommentAnswers: state.post.listCommentsAnswer,
    updateComment: state.post.updateComment,
    managerUpdateComment: state.manager.dataUpdateComment,
    managerUpdateAnswerComment: state.manager.dataUpdateAnswerComment,
    dataCreateComment: state.post.createComment,
    postDetail: state.post.postDetail,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail);
