// TextToSpeech.jsx
import React from 'react';
import LeftSidebar from 'components/left_sidebar';
import AddMetaTags from 'components/add_meta_tags';
import VoiceInput from './Components/VoiceInput';
import VoiceResult from './Components/VoiceResult';

const TextToSpeech = () => {
  return (
    <>
      <AddMetaTags title={"Giọng đọc"} />
      <div className="tts">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <h2 className='text-center'>Hai Nông TTS</h2>
            <div className="cont-center saved-items">
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <VoiceInput />
                </div>
                <div className='col-md-6 col-sm-12' style={{marginTop: "32px"}}>
                  <VoiceResult />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextToSpeech;