// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/EnterpriseDetail/Desktop/EnterpriseDescriptionDesktop.css */
.description {
  position: relative;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description.expanded {
  max-height: none;
}

.description.collapsed::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
}

.button-hide-expand {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 39px;
  margin: 10px auto 0px;
  color: rgb(24, 158, 255);
  font-size: 15px;
  font-weight: 400;
  line-height: 39px;
  text-align: center;
}

.button-hide-expand {
  outline: none !important;
}

.button-hide-expand:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/EnterpriseDetail/Mobile/EnterpriseDescriptionMobile.scss"],"names":[],"mappings":"AAAA,wEAAA;AACA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gCAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,kFAAA;AACF;;AACA;EACE,eAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,wBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AACA;EACE,wBAAA;AAEF;;AACA;EACE,wBAAA;EACA,2BAAA;EACA,oBAAA;AAEF","sourcesContent":["/* src/pages/EnterpriseDetail/Desktop/EnterpriseDescriptionDesktop.css */\n.description {\n  position: relative;\n  max-height: 200px;\n  overflow: hidden;\n  transition: max-height 0.3s ease;\n}\n\n.description.expanded {\n  max-height: none;\n}\n\n.description.collapsed::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 50px;\n  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));\n}\n.button-hide-expand {\n  cursor: pointer;\n  display: block;\n  width: 100%;\n  height: 39px;\n  margin: 10px auto 0px;\n  color: rgb(24, 158, 255);\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 39px;\n  text-align: center;\n}\n\n.button-hide-expand {\n  outline: none !important;\n}\n\n.button-hide-expand:focus {\n  outline: none !important;\n  box-shadow: none !important;\n  border: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
