import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MedalImg from '../../../assets/images/medal.png';
import SectionWrapper from '../wrapper/SectionWrapper';
const EnterpriseInformationMobile = ({ enterprise }) => {
  return (
    <>
      <SectionWrapper>
        <div className="my-2">
          <Row>
            <Col xs={4} className="position-relative">
              <Image src={enterprise.image} alt="anh doanh nghiep" thumbnail />
              {!!enterprise.prestige && (
                <div className="position-absolute" style={{ top: '-10px', right: '-3px' }}>
                  <Image src={MedalImg} alt="Medal" fluid style={{ width: '30px' }} />
                </div>
              )}
            </Col>
            <Col xs={8}>
              <div className="pl-2" style={{ fontSize: "13px" }}>
                <div className="address mb-2">
                  <span className="fw-bold">Địa chỉ: </span>
                  <span>{enterprise.address}, {enterprise.district_name}</span>
                </div>
                <div className="email mb-2">
                  <span className="fw-bold">Email: </span>
                  <a href={`mailto:${enterprise.email}`} target="_blank" rel="noopener noreferrer">
                    <span>{enterprise.email}</span>
                  </a>
                </div>
                <div className="phone mb-2">
                  <span className="fw-bold">Điện thoại: </span>
                  <a href={`tel:${enterprise.phone}`} target="_blank" rel="noopener noreferrer">
                    <span>{enterprise.phone}</span>
                  </a>
                </div>
                <div className="website mb-2">
                  <span className="fw-bold">Website: </span>
                  <a href={`https://${enterprise.website}`} target="_blank" rel="noopener noreferrer">
                    <span>{enterprise.website}</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </SectionWrapper>
    </>
  );
};

export default EnterpriseInformationMobile;