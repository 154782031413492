import { useEffect, useState } from "react";
import axiosService from "services/axiosService";
import { DOMAIN } from "constants";

function QuyCheHoatDong() {

  const [value, setValue] = useState(); 

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosService.get(`${DOMAIN}/api/v2/page`);
      const htmlPage = data.data.filter(page => page.id === 33)
      setValue(htmlPage[0].content)
    }

    fetchData()
  }, [])

  return (
    <>
      <div className="container full-height">
      <h3>Quy chế hoạt động</h3>
      <div className="mt-4">
      <div dangerouslySetInnerHTML={{ __html: value }} />
      </div>
      {/* <div style={{textAlign: 'center'}}>
        <p>Đang cập nhật!</p>
      </div> */}
    </div>
    </>
  )
}

export default QuyCheHoatDong;

