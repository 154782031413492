// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-centered {
  max-width: 50% !important;
}

.model-show-video {
  max-width: -webkit-fill-available;
}

.modal-show-media {
  max-height: 50%;
}

.uploaded-video {
  width: 100%;
  height: auto;
}

.icon-button {
  width: 100px;
  height: 100px;
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .modal-dialog-centered {
    max-width: 90% !important;
  }
  .icon-button {
    width: 60px;
    height: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/BusinessAssociation/BusinessInvoiceDetail/Components/refundModal.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AAAF;;AAGA;EACE;IACE,yBAAA;EAAF;EAGA;IACE,WAAA;IACA,YAAA;EADF;AACF","sourcesContent":["\n.modal-dialog-centered {\n  max-width: 50% !important;\n}\n\n.model-show-video {\n  max-width: -webkit-fill-available;\n}\n\n.modal-show-media {\n  max-height: 50%;\n}\n\n.uploaded-video {\n  width: 100%;\n  height: auto;\n}\n\n.icon-button {\n  width: 100px;\n  height: 100px;\n}\n\n.btn-close {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n}\n\n@media (max-width: 767px) {\n  .modal-dialog-centered {\n    max-width: 90% !important;\n  }\n\n  .icon-button {\n    width: 60px;\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
