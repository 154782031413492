import React  from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteConfirmModalComment(props) {
  const { onDelete, isOpen, onToggle } = props;
  const handleClose = () => {
    onToggle();
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} className="modal-confirm" style={{zIndex: 100000}} centered>
        <Modal.Header className="flex-column">
          <div className="icon-box">
            <i className="material-icons">&#xe872;</i>
          </div>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteConfirmModalComment;
