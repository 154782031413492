import { call, put, delay, all, race, select } from 'redux-saga/effects';

import {
  loginFail,
  loginSuccess,
  logoutSuccess,
  logoutFail,
  updateUserSuccess,
  updateUserFail,
  updatePhoneSuccess,
  updatePhoneFail,
  updateOtpSuccess,
  updateOtpFail,
  followUserSuccess,
  followUserFail,
  fetchFollowingSuccess,
  unFollowSuccess,
  sharePointSuccess,
  sharePointFailed,
  fetchSidebarFollowingsSuccess,
  fetchFollowPostSuccess,
  addCurrentURLSuccess,
  fetchInfoUserSuccess,
  unFollowFavPostSuccess,
  unlikeFollowingPostSuccess,
  unlikeFollowingPostFailed,
  likeFollowingPostFailed,
  likeFollowingPostSuccess,
  fetchPointOfUserSuccess,
  fetchPointOfUserFailed,
  updatePointOfUserSuccess
} from 'actions/auth';
import { fastFollowUserPost, fastUnFollowUserPost, fetchPostDetail } from 'actions/post';
import {
  login,
  logout,
  updateUser,
  updatePhone,
  updateOtp,
  requestLogout,
  followItem,
  fetchFollowing,
  unFollow,
  sharePoint,
  login_id_portal,
  fetchFollowPosts,
  getProfile,
} from 'apis/auth';
import { likePost, unlikePost } from 'apis/post';
import { STATUS_CODE } from 'constants';
import { toastError } from 'helpers/toastHelper';
import { toastSuccess } from '../../helpers/toastHelper';

export function* unFollowFavouritePost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unFollow, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unFollowFavPostSuccess(data));
    } else {
      // yield put(unFollowPostFailed(data));
    }
  } catch (error) {}
}

export function* userLogin({ payload }) {
  try {
    const { access_token } = payload;
    const resp = yield call(login, access_token);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(loginSuccess(data));
      yield call(login_id_portal);
    } else {
      yield put(loginFail(data));
    }
  } catch (error) {
    toastError(error);
  }
}

// new function
function* handleLogoutFailure() {
  yield put(logoutFail());
}

function* handleLogoutSuccess(data) {
  yield put(logoutSuccess(data));
  yield call(requestLogout);
}

export function* userLogout({ payload }) {
  const data = yield select(state => state.login.user_data);
  try {
    const { access_token } = payload;
    const { authToken } = data;
    if (!authToken) {
      yield call(handleLogoutFailure);
    } else {
      const { success, failure } = yield race({
        success: call(logout, access_token),
        failure: delay(10000), // giới hạn thời gian thực thi trong 10 giây quá 10 giây chuyển sang handle lỗi
      });
      if (failure) {
        yield call(handleLogoutFailure);
      } else {
        yield all([call(handleLogoutSuccess, success.data)]);
      }
    }
  } catch (error) {
    yield put(logoutFail(error.message));
  }
}

export function* userUpdateProfile({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateUser, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      toastSuccess('Cập nhật tài khoản thành công');
      yield put(updateUserSuccess(data));
    } else {
      yield put(updateUserFail(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdatePhone({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updatePhone, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updatePhoneSuccess(data));
    } else {
      yield put(updatePhoneFail(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdateOtp({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateOtp, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateOtpSuccess(data));
    } else {
      yield put(updateOtpFail(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* followUserItem({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(followItem, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(followUserSuccess(data));
      yield put(fastFollowUserPost(params.classable_id));
      // yield put(fetchPostDetail(params.post_id));
    } else {
      yield put(followUserFail(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchFollowings({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(fetchFollowing, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFollowingSuccess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* unFollowUser({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unFollow, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unFollowSuccess(data));
      yield put(fastUnFollowUserPost(params.classable_id));
      yield put(fetchPostDetail(params.post_id));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* sharePointUser({ payload }) {
  try {
    const { point } = yield select(state => state.login.pointOfUser);
    const { params } = payload;
    const resp = yield call(sharePoint, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const newPoint = point - Number(params.points)
      yield put(sharePointSuccess(data));
      yield put(updatePointOfUserSuccess(newPoint));
      toastSuccess("Chia sẻ điểm thành công")
    } else {
      yield put(sharePointFailed(data));
    }
  } catch (error) {
    yield put(sharePointFailed(error.data));
  }
}

export function* userFetchSidebarFollowing({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(fetchFollowing, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSidebarFollowingsSuccess(data));
    }
  } catch (error) {}
}

export function* userFetchListFollowingPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(fetchFollowPosts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFollowPostSuccess(data));
    }
  } catch (error) {}
}

export function* userShowDeepLink() {}

export function* addCurrentURL({ payload }) {
  const { params } = payload;
  yield put(addCurrentURLSuccess(params));
}

export function* fetchInfoUser({ payload }) {
  try {
    const { user_id } = payload;
    const resp = yield call(getProfile, user_id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchInfoUserSuccess(data));
    }
  } catch (error) {}
}

export function* userLikeFollowingPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likeFollowingPostSuccess(data));
    } else {
      yield put(likeFollowingPostFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* userUnikeFollowingPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unlikePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unlikeFollowingPostSuccess(params));
    } else {
      yield put(unlikeFollowingPostFailed(params));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchPointOfUser({ payload }) {
  try {
    const { user_id } = payload;
    const resp = yield call(getProfile, user_id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPointOfUserSuccess(data));
    } else {
      yield put(fetchPointOfUserFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updatePointOfUser( new_point ) {

  try{
    console.log(new_point);
    yield put(updatePointOfUserSuccess(new_point));
  }
  catch (error) {
    console.log(error);
  }
}
