import { useMemo } from 'react';
import moment from 'moment';

const useGenerateDateList = (fromDay, currentDay) => {
  const dates = useMemo(() => {
    let dates = [];
    let startDate = moment(fromDay, 'DD-MM-YYYY');
    let endDate = moment(currentDay, 'DD-MM-YYYY');

    // Tính toán số ngày giữa fromDay và currentDay
    let diffDays = endDate.diff(startDate, 'days');

    // Tính toán khoảng cách giữa mỗi ngày
    let interval = Math.floor(diffDays / 5);

    // Thêm fromDay vào mảng
    dates.push(startDate.format('DD-MM-YYYY'));

    // Tính toán và thêm các ngày còn lại vào mảng
    for (let i = 1; i < 5; i++) {
      let date = startDate.clone().add(interval * i, 'days');
      dates.push(date.format('DD-MM-YYYY'));
    }

    // Thêm currentDay vào mảng
    dates.push(endDate.format('DD-MM-YYYY'));

    return dates;
  }, [fromDay, currentDay]); // Phụ thuộc vào fromDay và currentDay

  return dates;
};

export default useGenerateDateList;
