import { React, useState } from 'react';
import parse from 'html-react-parser';
import "../DiagnosticResult.scss"
const PlantDiagnosisResult = ({ data }) => {
  const descText = data?.category?.description;
  const [isShow, setIsShow] = useState(false);
  const descriptText = $('.description-text').height();
  const validHeight = descriptText <= 180;
  const handleShowMore = () => {
    setIsShow(true);
    $('.default-description').addClass('is-showed');
  };

  return (
    <>
      <h2 className='text-center'>Chẩn đoán cây trồng</h2>
      <div className="cont-center saved-items">
        <p><strong className="primary-clr">Nhận dạng cây:</strong> {data.category.name || 'Không xác định'}</p>
        <img
          src={data.category.image || 'https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg'}
          alt='Hình ảnh chẩn đoán'
          className="image-diagnostis box-info"
        />
        <div className="box-info mt-3 p-3">
          <div className="default-description">
            <div className="description-text">
              {descText && parse(descText.replace(/<\/p\>/g, '<br />'))}
            </div>
          </div>
          <div
            className="post-more border-0 mt-0 w-100 mb-3"
            onClick={() => handleShowMore()}
            hidden={(!isShow && validHeight) || isShow}
          >
            <u className="hover txt fw-500">Xem thêm</u>
          </div>
        </div>
      </div>
    </>
  )
}
export default PlantDiagnosisResult;

