import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Modal, Button } from 'react-bootstrap';
import NextArrow from '../../../assets/images/next-arrow.png';
import PrevArrow from '../../../assets/images/prev-arrow.png';

const isImage = (fileName) => {
  return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
};

const isVideo = (fileName) => {
  return /\.(mp4|mov|avi|wmv|mkv)$/i.test(fileName);
};

const ShowMediaModal = ({title, show, handleClose, mediaList = [] }) => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  function NextSlide(props) {
    const { onClick } = props;
    return (
      <div className="position-absolute z-1 pointer slider-next-right" onClick={onClick}>
        <div className="slider-next-arrow">
          <img src={NextArrow} alt="next list" />
        </div>
      </div>
    );
  }

  function PrevSlide(props) {
    const { onClick } = props;
    return (
      <div className="position-absolute z-1 pointer slider-prev-left" onClick={onClick}>
        <div className="slider-prev-arrow">
          <img src={PrevArrow} alt="prev list" />
        </div>
      </div>
    );
  }

  const slickNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const slickPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: mediaList.length > 1, 
    centerPadding: '60px',
    slidesToShow: 1,
    speed: 500,
    nextArrow: <NextSlide onClick={slickNext} />,
    prevArrow: <PrevSlide onClick={slickPrev} />,
    afterChange: (index) => setCurrentIndex(index),
    responsive: [
      {
        breakpoint: 628,
        settings: {
          swipeToSlide: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Slider {...settings} ref={sliderRef}>
          {Array.isArray(mediaList) && mediaList.length > 0 ? (
            mediaList.map((media, index) => (
              <div key={index}>
                {media && media.name ? (
                  isImage(media.name) ? (
                    <img
                      src={media.name}
                      alt={`media-${index}`}
                      className="img-fluid model-show-video"
                      style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                    />
                  ) : isVideo(media.name) ? (
                    <video controls className="video-cover" style={{ width: '100%' }}>
                      <source src={media.name} type="video/mp4" />
                    </video>
                  ) : (
                    <p>Loại phương tiện không được hỗ trợ</p>
                  )
                ) : (
                  <p>Phương tiện không có sẵn</p>
                )}
              </div>
            ))
          ) : (
            <p>Phương tiện không có sẵn</p>
          )}
        </Slider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowMediaModal;
