const CREATE_REFUND_INVOICE = "CREATE_REFUND_INVOICE";
const CREATE_REFUND_INVOICE_SUCCESS = "CREATE_REFUND_INVOICE_SUCCESS";
const CREATE_REFUND_INVOICE_FAILED = "CREATE_REFUND_INVOICE_FAILED";

export { CREATE_REFUND_INVOICE, CREATE_REFUND_INVOICE_SUCCESS, CREATE_REFUND_INVOICE_FAILED };

export const FETCH_BUYING_REFUND_INVOICES = "FETCH_BUYING_REFUND_INVOICES";
export const FETCH_BUYING_REFUND_INVOICES_SUCCESS = "FETCH_BUYING_REFUND_INVOICES_SUCCESS";
export const FETCH_BUYING_REFUND_INVOICES_FAILED = "FETCH_BUYING_REFUND_INVOICES_FAILED";

export const FETCH_SELLING_REFUND_INVOICES = "FETCH_SELLING_REFUND_INVOICES";
export const FETCH_SELLING_REFUND_INVOICES_SUCCESS = "FETCH_SELLING_REFUND_INVOICES_SUCCESS";
export const FETCH_SELLING_REFUND_INVOICES_FAILED = "FETCH_SELLING_REFUND_INVOICES_FAILED";

export const FETCH_DETAIL_REFUND_INVOICE = "FETCH_DETAIL_REFUND_INVOICE";
export const FETCH_DETAIL_REFUND_INVOICE_SUCCESS = "FETCH_DETAIL_REFUND_INVOICE_SUCCESS";
export const FETCH_DETAIL_REFUND_INVOICE_FAILED = "FETCH_DETAIL_REFUND_INVOICE_FAILED";

export const REQUEST_COMPLAINT_STATUS = "REQUEST_COMPLAINT_STATUS";
export const REQUEST_COMPLAINT_STATUS_SUCCESS = "REQUEST_COMPLAINT_STATUS_SUCCESS";
export const REQUEST_COMPLAINT_STATUS_FAILED = "REQUEST_COMPLAINT_STATUS_FAILED";

export const UPDATE_STATUS_REFUND_INVOICE = "UPDATE_STATUS_REFUND_INVOICE";
export const UPDATE_STATUS_REFUND_INVOICE_SUCCESS = "UPDATE_STATUS_REFUND_INVOICE_SUCCESS";
export const UPDATE_STATUS_REFUND_INVOICE_FAILED = "UPDATE_STATUS_REFUND_INVOICE_FAILED";

